import {
  AppointmentEstablishedData,
  AppointmentState,
  BE_API_CheckEstablished,
  BE_Appointment,
  BE_Established,
  BE_InsuranceRequestBase,
  BE_MissingData,
  BE_PatientInfo,
  BE_PatientInfoExtended,
  Mapping
} from "@/types";

export const APPOINTMENT_BE_TO_BE_MISSING_DATA: Mapping<
  BE_Appointment,
  BE_MissingData
> = {
  "patientInfo.address": "address",
  "patientInfo.phoneNumber": "phoneNumber",
  "patientInfo.email": "email",
  "patientInfo.ethnicity": "ethnicity",
  "patientInfo.race": "race",
  "patientInfo.preferredLanguage": "preferredLanguage"
};

export const APPOINTMENT_TO_BE_PATIENT_INFO: Mapping<
  AppointmentState,
  BE_PatientInfo & BE_PatientInfoExtended
> = {
  "contact.email": "email",
  "info.firstName": "firstName",
  "info.lastName": "lastName",
  "info.gender": "gender"
};

export const APPOINTMENT_TO_BE_INSURANCE_REQUEST_MAP: Mapping<
  AppointmentState,
  BE_InsuranceRequestBase & BE_Established
> = {
  patient: "patient",
  doctor: "idProvider",
  "visit.reason": "idVisitType",
  idTimeSlot: "idTimeSlot",
  idPatient: "idPatient"
};

export const BE_ESTABLISHED_DATA_TO_ESTABLISHED_DATA_MAP: Mapping<
  Awaited<ReturnType<BE_API_CheckEstablished>>,
  AppointmentEstablishedData
> = {
  idPatient: "idPatient",
  "insuranceData.insuranceNameId": "insurance.id",
  "insuranceData.insuranceName": "insurance.name",
  "insuranceData.insuranceId": "insurance.number",
  "patientInfo.phone": "contact.phone",
  "patientInfo.state": "contact.address.state",
  "patientInfo.city": "contact.address.city",
  "patientInfo.street": "contact.address.street",
  "patientInfo.zip": "contact.address.zip",
  missingData: "contactMissingData"
};

export const APPOINTMENT_TO_APPOINTMENT_BE_MAP: Mapping<
  AppointmentState,
  BE_Appointment
> = {
  patient: "patient",
  token: "token",
  doctor: "provider",
  idTimeSlot: "timeSlot",
  "visit.reason": "reason",
  "visit.location": "location",
  "contact.address.state": "patientInfo.address.state",
  "contact.address.street": "patientInfo.address.street",
  "contact.address.street2": "patientInfo.address.addressLine2",
  "contact.address.city": "patientInfo.address.city",
  "contact.address.zip": "patientInfo.address.zipCode",
  "contact.email": "patientInfo.email",
  "contact.race": "patientInfo.race",
  "contact.ethnicity": "patientInfo.ethnicity",
  "contact.language": "patientInfo.preferredLanguage",
  "contact.phone": "patientInfo.phoneNumber.number",
  "info.firstName": "patientInfo.firstName",
  "info.lastName": "patientInfo.lastName",
  "info.gender": "patientInfo.gender",
  "parent_info.firstName": "patientInfo.mother.firstName",
  "parent_info.lastName": "patientInfo.mother.maidenName",
  "insurance.id": "patientInfo.insurance.id",
  "insurance.name": "patientInfo.insurance.name",
  "insurance.number": "patientInfo.insurance.code",
  "insurance.holder.firstName": "patientInfo.pph.firstName",
  "insurance.holder.lastName": "patientInfo.pph.lastName",
  "insurance.holder.gender": "patientInfo.pph.gender",
  signature: "formSignature",
  "sign.firstName": "formFirstName",
  "sign.lastName": "formLastName",
  consents: "consents"
};
