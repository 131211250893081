import {
  AppointmentStage,
  AppointmentState,
  Patient,
  PaymentType
} from "@/types";

const initialState: AppointmentState = {
  idPatient: null,
  token: null,
  stage: AppointmentStage.SEARCH,

  searchParams: "",

  patient: Patient.PEDIATRIC,
  visit: {
    type: null,
    reason: null,
    globalCategory: null,
    location: null
  },
  doctor: null,
  doctorName: "",
  specialty: null,
  idTimeSlot: null,
  date: null,
  time: "",
  timeEnd: "",
  practice: null,
  info: {
    age: null,
    gender: null,
    birthday: null,
    firstName: "",
    lastName: ""
  },
  parent_info: {
    firstName: "",
    lastName: "",
    birthday: null
  },
  contact: {
    address: {
      city: "",
      state: "",
      street: "",
      zip: "",
      street2: ""
    },
    email: "",
    phone: "",
    ethnicity: "",
    language: "",
    race: ""
  },
  contactMissingData: {},
  insurance: {
    id: null,
    name: "",
    number: "",
    holder: null
  },
  insuranceCostsData: null,
  preScreeningData: null,
  preRegQuestionnaireList: [],
  preRegQuestionnaires: {},
  preRegQuestionnaireActive: null,
  costs1: [],
  consentsData: null,

  consents: {
    generalConsent: {
      agree: false
    },
    healthixConsent: {
      agree: true,
      allow: false
    },
    telemedicineConsent: {
      agree: false
    },
    paymentConsent: {
      agree: false
    },
    authorizationConsent: {
      agree: false,
      ccEmail: ""
    },
    paymentType: PaymentType.NEW_CARD
  },
  sign: {
    firstName: "",
    lastName: ""
  },
  signature: null,
  payment: {
    clientSecret: ""
  },
  errorMessage: "",
  errorCode: null,
  errorAction: "",
  loading: false
};

export default initialState;
