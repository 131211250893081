import { createAsyncThunk } from "@reduxjs/toolkit";
import _ from "@/lodash";

import {
  AppointmentEstablishedData,
  AppointmentStage,
  AppointmentState,
  AppStateDef,
  BE_ConsentsResponse,
  BE_InsuranceVerifyResponse,
  BE_PreRegQuestionAnswer,
  BE_PreRegQuestionnaire,
  BE_PreRegQuestionnaireListResponse,
  BE_PreScreeningQuestionAnswer,
  BE_PreScreeningQuestionnaireResponse,
  BE_PreScreeningQuestionnaireResult,
  Practice,
  SearchResult,
  SearchStage,
  TokenResponse
} from "@/types";
import { API } from "@/constants/api";
import type { FetchError } from "@/constants/api/customFetch";
import type { BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";

export type AsyncThunkError = {
  message: string;
  code?: number;
};

const _handleThunkErrors =
  <Ret extends any = any, Arg extends any = any>(
    fn: (
      arg: Arg,
      thunkAPI: BaseThunkAPI<AppStateDef, any, any, AsyncThunkError>
    ) => Promise<Ret | ReturnType<typeof thunkAPI.rejectWithValue>>
  ): typeof fn =>
  async (arg, thunkAPI) => {
    try {
      return await fn(arg, thunkAPI);
    } catch (e: Error | FetchError | any) {
      return thunkAPI.rejectWithValue({
        message: e?.message ?? "Unknown error occurred.",
        code: e?.statusCode
      });
    }
  };

export const createAppointment = createAsyncThunk<
  string,
  void,
  { state: AppStateDef; rejectValue: AsyncThunkError }
>(
  "thunk/createAppointment",
  _handleThunkErrors(async (__, thunkAPI) => {
    const appointmentState = thunkAPI.getState().appointment;
    const res = await API().state.bookAppointment(appointmentState);
    if (res.error) throw new Error(res.error);
    return res.clientSecret;
  })
);

export const getConsents = createAsyncThunk<
  BE_ConsentsResponse,
  void,
  { state: AppStateDef; rejectValue: AsyncThunkError }
>(
  "thunk/consents",
  _handleThunkErrors(async (arg, thunkAPI) => {
    const { token, patient } = thunkAPI.getState().appointment;
    if (!token) throw new Error("Token is missing.");
    return await API().getConsents({
      token,
      patient
    });
  })
);

export const fetchPreRegQuestionnaireList = createAsyncThunk<
  BE_PreRegQuestionnaireListResponse,
  void,
  { state: AppStateDef; rejectValue: AsyncThunkError }
>(
  "thunk/preRegQuestionnaireList",
  _handleThunkErrors(async (arg, thunkAPI) => {
    const appointmentState = thunkAPI.getState().appointment;
    if (!appointmentState.token) throw new Error("No token provided.");

    return API().preRegistration.list({
      token: appointmentState.token,
      patient: appointmentState.patient
    });
  })
);

export const getPreRegQuestionnaire = createAsyncThunk<
  BE_PreRegQuestionnaire,
  number,
  { state: AppStateDef; rejectValue: AsyncThunkError }
>(
  "",
  _handleThunkErrors(async (arg, thunkAPI) => {
    const appointmentState = thunkAPI.getState().appointment;
    if (
      appointmentState.preRegQuestionnaires[arg] &&
      !!appointmentState.preRegQuestionnaires[arg].questionnaire
    )
      return appointmentState.preRegQuestionnaires[arg].questionnaire!;

    if (
      !appointmentState.token ||
      appointmentState.stage !== AppointmentStage.QUESTIONS
    )
      throw new Error("Wrong state.");

    const res = await API().preRegistration.get({
      token: appointmentState.token,
      patient: appointmentState.patient,
      questionnaireId: arg
    });

    if (!res.result || !res.questionnaire)
      throw new Error("No questionnaire found.");
    return res.questionnaire;
  })
);

export const answerPreRegQuestionnaire = createAsyncThunk<
  void,
  number,
  { state: AppStateDef; rejectValue: AsyncThunkError }
>(
  "thunk/preRegQuestionnaireAnswer",
  _handleThunkErrors(async (arg, thunkAPI) => {
    const appointmentState = thunkAPI.getState().appointment;
    if (
      !appointmentState.token ||
      !appointmentState.doctor ||
      appointmentState.stage !== AppointmentStage.QUESTIONS
    )
      throw new Error("Wrong state.");

    const answersObject = appointmentState.preRegQuestionnaires[arg]
      ? appointmentState.preRegQuestionnaires[arg].answers
      : {};

    const answers = Object.entries(answersObject)
      .filter(([_, answer]) => answer !== undefined)
      .map<BE_PreRegQuestionAnswer>(([questionIdent, answer]) => ({
        answer: JSON.stringify(answer),
        questionIdent
      }));

    return API().preRegistration.save({
      token: appointmentState.token,
      patient: appointmentState.patient,
      idProvider: appointmentState.doctor,
      answers,
      idQuestionnaire: arg
    });
  })
);

export const answerPreScreening = createAsyncThunk<
  BE_PreScreeningQuestionnaireResult,
  BE_PreScreeningQuestionAnswer[],
  { state: AppStateDef; rejectValue: AsyncThunkError }
>(
  "thunk/preScreeningAnswer",
  _handleThunkErrors(async (arg, thunkAPI) => {
    const appointmentState = thunkAPI.getState().appointment;
    if (
      !appointmentState.token ||
      appointmentState.stage !== AppointmentStage.PRE_SCREENING
    )
      throw new Error("Wrong state.");

    await API().preScreening.save({
      token: appointmentState.token!,
      patient: appointmentState.patient,
      answers: arg
    });
    const result = await API().preScreening.results({
      token: appointmentState.token!,
      patient: appointmentState.patient
    });

    if (result.text && result.text.length > 0)
      return thunkAPI.rejectWithValue({
        message: result.text.join(" \n"),
        code: result.result ? 200 : 403
      });

    return result;
  })
);

export const fetchPreScreening = createAsyncThunk<
  BE_PreScreeningQuestionnaireResponse,
  void,
  { state: AppStateDef; rejectValue: AsyncThunkError }
>(
  "thunk/preScreeningFetch",
  _handleThunkErrors(async (_, thunkAPI) => {
    const appointmentState = thunkAPI.getState().appointment;
    if (!appointmentState.token) throw new Error("Wrong state.");
    return await API().preScreening.get({
      patient: appointmentState.patient,
      token: appointmentState.token!
    });
  })
);

export const setInsurance = createAsyncThunk<
  TokenResponse,
  AppointmentState["insurance"] | null,
  { state: AppStateDef; rejectValue: AsyncThunkError }
>(
  "thunk/setInsurance",
  _handleThunkErrors(async (newInsurance = null, thunkAPI) => {
    const appointmentState = thunkAPI.getState().appointment;

    const isEstablished = appointmentState.practice === Practice.ESTABLISHED;
    const establishedInsurance = appointmentState.insurance;

    const confirmOnBE =
      isEstablished &&
      _.isEqual(
        _.omit(newInsurance, "holder"),
        _.omit(establishedInsurance, "holder")
      );

    if (confirmOnBE)
      return API().insurance.confirmSet({
        patient: appointmentState.patient,
        idProvider: appointmentState.doctor!,
        idVisitType: appointmentState.visit.reason!,
        idTimeSlot: appointmentState.idTimeSlot!,
        idPatient: appointmentState.idPatient!
      });

    return API().insurance.set({
      state: appointmentState,
      newInsurance
    });
  })
);

export const verifyInsurance = createAsyncThunk<
  BE_InsuranceVerifyResponse,
  void,
  { state: AppStateDef; rejectValue: AsyncThunkError }
>(
  "thunk/verifyInsurance",
  _handleThunkErrors(async (arg, thunkAPI) => {
    const appointmentState = thunkAPI.getState().appointment;

    if (!appointmentState.token)
      throw new Error("No token. Set insurance first.");

    return API().insurance.verify({
      token: appointmentState.token,
      patient: appointmentState.patient
    });
  })
);

export const fetchEstablished = createAsyncThunk<
  Partial<AppointmentEstablishedData> & {
    contactMissingData: AppointmentEstablishedData["contactMissingData"];
    results: boolean;
  },
  void,
  { state: AppStateDef; rejectValue: AsyncThunkError }
>(
  "thunk/fetchEstablished",
  _handleThunkErrors(async (arg, thunkAPI) => {
    const appointmentState = thunkAPI.getState().appointment;
    if (appointmentState.stage !== AppointmentStage.INFO)
      throw new Error("Wrong stage.");

    // if (appointmentState.practice !== null)
    //   return {
    //     results: appointmentState.practice === Practice.ESTABLISHED,
    //   };

    try {
      return await API().state.checkEstablished({
        ...appointmentState.info,
        patient: appointmentState.patient
      });
    } catch (err: any) {
      return {
        results: false,
        contactMissingData: {
          address: true,
          email: true,
          phoneNumber: true,
          ethnicity: true,
          race: true,
          preferredLanguage: true
        }
      };
    }
  })
);

export const fetchOptions1 = createAsyncThunk<
  AppStateDef["data"]["options1"],
  void,
  { state: AppStateDef; rejectValue: AsyncThunkError }
>(
  "thunks/fetchOptions1",
  _handleThunkErrors(async (arg, thunkAPI) => {
    const searchState = thunkAPI.getState().search;
    return await API().search.fetchOptions1({
      ...searchState.search1,
      patient: searchState.patient,
      age: searchState.age,
      dob: searchState.birthday,
      date: searchState.search2.date
    });
  })
);

export const fetchOptions2 = createAsyncThunk<
  AppStateDef["data"]["options2"],
  void,
  { state: AppStateDef; rejectValue: AsyncThunkError }
>(
  "thunks/fetchOptions2",
  _handleThunkErrors(async (arg, thunkAPI) => {
    const searchState = thunkAPI.getState().search;
    if (searchState.stage < SearchStage.SEARCH2)
      throw new Error("Not in a valid stage!");

    return await API().search.fetchOptions2({
      ...(searchState.search1 as any),
      ...searchState.search2,
      patient: searchState.patient,
      age: searchState.age!,
      dob: searchState.birthday!
    });
  })
);

export const fetchConstants = createAsyncThunk<
  AppStateDef["data"]["constants"],
  AppStateDef["search"]["patient"],
  { state: AppStateDef; rejectValue: AsyncThunkError }
>(
  "thunks/fetchConstants",
  _handleThunkErrors(async (arg) => {
    return await API().fetchConstants({ patient: arg });
  })
);

export const fetchSearchResults = createAsyncThunk<
  SearchResult[],
  void,
  { state: AppStateDef; rejectValue: AsyncThunkError }
>(
  "thunks/search",
  _handleThunkErrors((arg, thunkAPI) => {
    const searchState = thunkAPI.getState().search;
    return API()
      .search.fetchResults({
        ...searchState.search2,
        ...searchState.search1,
        patient: searchState.patient,
        age: searchState.age!,
        dob: searchState.birthday!
      })
      .then((r) => r.results);
  })
);
