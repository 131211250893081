import _ from "@/lodash";

import { isNotNull } from "@/helpers";
import {
  AppointmentStage,
  AppointmentState,
  Patient,
  PaymentType,
  Practice,
  VisitType
} from "@/types";

const isPayed = () => true;

export const appointmentStageCheckers: Record<
  AppointmentStage,
  (state: AppointmentState) => boolean
> = {
  [AppointmentStage.SEARCH]: () => true,
  [AppointmentStage.INFO]: (state) =>
    state.stage === AppointmentStage.SEARCH &&
    isNotNull(state.doctor) &&
    isNotNull(state.specialty) &&
    // isNotNull(state.visit.condition) &&
    (state.visit.type !== VisitType.OFFICE ||
      isNotNull(state.visit.location)) &&
    isNotNull(state.visit.globalCategory) &&
    isNotNull(state.visit.type) &&
    isNotNull(state.idTimeSlot) &&
    isNotNull(state.date) &&
    isNotNull(state.time),
  [AppointmentStage.PARENT]: (state) =>
    state.stage === AppointmentStage.INFO &&
    isNotNull(state.info.firstName) &&
    isNotNull(state.info.lastName) &&
    isNotNull(state.info.gender) &&
    isNotNull(state.info.birthday),
  // && isNotNull(state.practice),
  [AppointmentStage.CONTACT]: (state) =>
    state.stage === AppointmentStage.PARENT &&
    (state.patient !== Patient.PEDIATRIC ||
      state.practice === Practice.ESTABLISHED ||
      isNotNull(state.parent_info)) &&
    isNotNull(state.practice),
  [AppointmentStage.INSURANCE]: (state) =>
    state.stage === AppointmentStage.CONTACT &&
    isNotNull(
      _.pick(state.contact, [
        ...(state.contactMissingData.phoneNumber ? ["phone"] : []),
        ...(state.contactMissingData.email ? ["email"] : []),
        ...(state.contactMissingData.address
          ? ["address.street", "address.city", "address.zip", "address.state"]
          : []),
        ...(state.contactMissingData.ethnicity ? ["ethnicity"] : []),
        ...(state.contactMissingData.race ? ["race"] : []),
        ...(state.contactMissingData.preferredLanguage ? ["language"] : [])
      ])
    ),
  [AppointmentStage.PRE_SCREENING]: (state) =>
    state.stage === AppointmentStage.INSURANCE && isNotNull(state.token),
  [AppointmentStage.COSTS1]: (state) =>
    state.stage === AppointmentStage.PRE_SCREENING,
  // && isNotNull(state.insurance)
  [AppointmentStage.QUESTIONS]: (state) =>
    state.stage === AppointmentStage.COSTS1,
  [AppointmentStage.COSTS2]: (state) =>
    state.stage === AppointmentStage.QUESTIONS,
  [AppointmentStage.SIGN]: (state) =>
    state.stage === AppointmentStage.COSTS2 && state.consentsData !== null,
  [AppointmentStage.PAYMENT]: (state) =>
    state.stage === AppointmentStage.SIGN &&
    isNotNull(state.sign) &&
    ((!state.consents.authorizationConsent.agree &&
      !state.consents.paymentConsent.agree) ||
      state.consents.paymentType === PaymentType.NEW_CARD ||
      !!state.consents.authorizationConsent.ccEmail) &&
    // checkSignee(state) &&
    checkConsents(state),
  [AppointmentStage.DONE]: (state) =>
    state.stage === AppointmentStage.PAYMENT && isPayed(),
  [AppointmentStage.DONE + 1]: () => false
};

// const checkSignee = (state: AppointmentState): boolean => {
//   const { firstName, lastName } =
//     state.patient === Patient.CHILD ? state.parent_info : state.info;
//   return state.sign.firstName === firstName && state.sign.lastName === lastName;
// };

const checkConsents = (state: AppointmentState): boolean => {
  if (!state.consentsData) return false;
  return (
    (!state.consentsData.consents.healthixConsent ||
      !state.consentsData.consents.healthixConsent.required ||
      state.consents.healthixConsent.agree) &&
    (!state.consentsData.consents.authorizationConsent ||
      !state.consentsData.consents.authorizationConsent.required ||
      state.consents.authorizationConsent.agree) &&
    (!state.consentsData.consents.paymentConsent ||
      !state.consentsData.consents.paymentConsent.required ||
      state.consents.paymentConsent.agree) &&
    (!state.consentsData.consents.telemedicineConsent ||
      !state.consentsData.consents.telemedicineConsent.required ||
      state.consents.telemedicineConsent.agree) &&
    (!state.consentsData.consents.generalConsent ||
      !state.consentsData.consents.generalConsent.required ||
      state.consents.generalConsent.agree) &&
    true
  );
};
