import Popup from "../Popup";
import Button from "../Inputs/Button";

import { Dialog, DialogResponse } from "@/types";

import classes from "./Dialog.module.scss";

type DialogProps = Dialog & {
  onClose?: (value: DialogResponse) => void | Promise<void>;
};

const Dialog: (props: DialogProps) => JSX.Element = ({
  heading,
  message,
  icon = "fas fa-exclamation-triangle",
  type,
  buttonText = "Confirm",
  buttonText2,
  open,
  onClose = () => {}
}) => {
  switch (type) {
    case "INFO":
      icon = "fas fa-info-circle";
      break;
    case "WARNING":
      icon = "fas fa-exclamation-triangle";
      break;
    default:
      break;
  }

  if (!open) return <></>;

  return (
    <Popup onClose={() => onClose(DialogResponse.CANCEL)}>
      <div className={classes.dialog}>
        <h1>
          <i className={icon} />
        </h1>
        <h2>{heading}</h2>
        <p>{message}</p>
        <div className={classes.buttons}>
          <Button
            noAnimate
            autoLoading
            onClick={() => onClose(DialogResponse.CONFIRM)}
          >
            {buttonText}
          </Button>
          {buttonText2 && (
            <Button
              noAnimate
              secondary
              onClick={() => onClose(DialogResponse.BUTTON2)}
            >
              {buttonText2}
            </Button>
          )}
        </div>
        <span
          className={classes.close}
          onClick={() => onClose(DialogResponse.CANCEL)}
        >
          <i className="fas fa-times" />
        </span>
      </div>
    </Popup>
  );
};
export default Dialog;
