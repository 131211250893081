import dynamic from "next/dynamic";
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

const Popup = dynamic(() => import("@/components/UI/Popup"));
import { arrayOf } from "@/helpers/general";
import { LoadState } from "@/types";

export const moveWithMouse = (querySelector: string) => {
  const mouseMoveHandle: MouseEventHandler<HTMLElement> = (e) => {
    const btn = e.currentTarget as HTMLElement;
    const span = btn.querySelector(querySelector)! as HTMLElement;
    if (!span) return;
    const pos = btn.getBoundingClientRect();
    span.style.top = `${e.pageY - pos.top}px`;
    span.style.left = `${e.pageX - pos.left}px`;
  };
  return {
    onMouseMove: mouseMoveHandle,
    onMouseOut: mouseMoveHandle
  };
};

export const useDataFetch = <T extends any = any>(
  promise: () => Promise<T>,
  defaultValue: T,
  isReady?: boolean
): [T, LoadState] => {
  const mounted = useMounted();
  const [val, setVal] = useState<T>(defaultValue);
  const [loader, setLoader] = useState<LoadState>(LoadState.DEFAULT);

  useEffect(() => {
    if (!mounted) return;
    if (isReady !== false) {
      if (loader === LoadState.DEFAULT) {
        setLoader(LoadState.LOADING);
        promise().then((v) => {
          setVal(v);
          setLoader(LoadState.LOADED);
        });
      }
    } else {
      setVal(defaultValue);
      setLoader(LoadState.DEFAULT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader, promise, mounted, isReady]);

  return [val, loader];
};

export const useMediaQuery = () => {
  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);

  useEffect(() => {
    const listenFn = () => {
      setWidth(document.body.clientWidth);
      setHeight(document.body.clientHeight);
    };
    listenFn();

    window.addEventListener("resize", listenFn);
    return () => {
      window.removeEventListener("resize", listenFn);
    };
  }, []);

  return [width, height];
};
export const useIsMobile = () => {
  const [width] = useMediaQuery();

  return width ? width < 768 : false;
};

export const useMultiAgreement = (count: number, initVal = false) => {
  const [radioState, setRadioState] = useState<boolean[]>(
    arrayOf(count, initVal)
  );

  const radioValue = useCallback(
    (index: number) => radioState[index],
    [radioState]
  );
  const radioOnChange = useCallback(
    (i: number) => (val: any) =>
      setRadioState((s) => {
        const newS = [...s];
        newS[i] = val;
        return newS;
      }),
    []
  );

  const hasAgreed = radioState.reduce((prev, curr) => prev && curr, true);

  return {
    hasAgreed,
    radioOnChange,
    radioValue
  };
};

export const useMounted = () => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  return mounted;
};

export const AutoFocus = (props: { delay?: number }): JSX.Element => {
  const ref = useRef<HTMLElement | null>(null);
  useEffect(() => {
    setTimeout(
      () =>
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start"
        }),
      props.delay ?? 0
    );
  }, [props.delay]);
  return <i ref={ref} />;
};

export const PageLoading = (): JSX.Element => (
  <Popup open={true}>
    <div className="text-center p-4">
      <h1>Welcome to {process.env.NEXT_PUBLIC_NAME}!</h1>
      <h4>Loading...</h4>
      <p>
        <i className="fas fa-spinner fa-spin fa-5x" />
      </p>
    </div>
  </Popup>
);

export const useScrollTop = (boundary: number) => {
  const [isPast, setIsPast] = useState(false);

  useEffect(() => {
    const fn = () => {
      const scrollTop = document.scrollingElement?.scrollTop;
      if (scrollTop !== undefined) {
        if (scrollTop > boundary !== isPast) setIsPast(scrollTop > boundary);
      }
    };

    fn();
    document.addEventListener("scroll", fn);
    return () => {
      document.removeEventListener("scroll", fn);
    };
  }, [isPast, boundary]);

  return isPast;
};
