import { useRouter } from "next/router";
import { useCallback } from "react";

import { transformToAppointmentDetails } from "@/components/UI/Appointments/AppointmentInfoList";

import { useSliceStages, useSliceValues } from "../util";
import { searchSlice } from "./slice";
import { searchStageCheckers } from "./stages";
import { useSearchParams } from "./middleware";
import { useData } from "../data/helpers";
import { fetchSearchResults } from "../thunks";
import { searchToAppointment } from "../actions";

import { stringToDate } from "@/helpers";
import {
  AppointmentInfoProps,
  SearchResult,
  SearchStage,
  SearchState,
  TimeSlot
} from "@/types";
import { useAppDispatch } from "@/store";

export const useSearch = () => {
  const dispatch = useAppDispatch();
  const v = useSliceValues(searchSlice);
  const s = useSliceStages<SearchStage, SearchState>(searchSlice);

  const { getTextByValue, getLocationById } = useData();
  const { asPath } = useRouter();

  const { valueSet, resetState: _resetState } = v;
  const { selectedResult, selectedTime, patient, isSearching } = v.state;
  const { type: visitType, globalCategory, reason } = v.state.search1;

  const resetState = useCallback(
    (omitPaths: string[] = []) => _resetState([...omitPaths, "didInitParams"]),
    [_resetState]
  );

  const isIframe = useCallback(
    () => !!window.top && window.self !== window.top,
    []
  );
  const redirectToFullPage = useCallback(
    (appendParams?: Record<string, string>) => {
      if (!window.top) return;

      const url = new URL(window.location.href);
      if (process.env.NEXT_PUBLIC_PROTOCOL)
        url.protocol = process.env.NEXT_PUBLIC_PROTOCOL;
      if (process.env.NEXT_PUBLIC_HOST)
        url.hostname = process.env.NEXT_PUBLIC_HOST;
      if (process.env.NEXT_PUBLIC_PORT) url.port = process.env.NEXT_PUBLIC_PORT;

      if (appendParams)
        Object.keys(appendParams).forEach((k) =>
          url.searchParams.set(k, appendParams[k])
        );

      window.top.location.href = url.toString();
    },
    []
  );

  const selectAppointment = useCallback(
    (sel: SearchResult | null, time: TimeSlot | null) => {
      valueSet("selectedTime", time);
      valueSet("selectedResult", sel);
    },
    [valueSet]
  );

  const getSelectedTermInfo = useCallback(
    (): AppointmentInfoProps =>
      selectedResult
        ? {
            doctorName: selectedResult.doctor.name,
            dateString: stringToDate(selectedResult.date).toString(),
            location:
              getLocationById(selectedResult.doctor.location, visitType)
                ?.address1 ?? "",
            time: selectedTime?.startTime ?? "",
            timeEnd: selectedTime?.endTime ?? "",
            details: transformToAppointmentDetails({
              patient,
              globalCategory: getTextByValue(
                "globalCategories",
                globalCategory
              ),
              visitType: getTextByValue("visitTypes", visitType),
              visitReason: getTextByValue("reasons", reason)
            })
          }
        : {
            doctorName: "",
            dateString: "",
            location: "",
            time: "",
            timeEnd: "",
            details: transformToAppointmentDetails({
              patient,
              globalCategory: getTextByValue(
                "globalCategories",
                globalCategory
              ),
              visitType: getTextByValue("visitTypes", visitType),
              visitReason: getTextByValue("reasons", reason)
            })
          },
    [
      selectedResult,
      getLocationById,
      getTextByValue,
      selectedTime,
      visitType,
      reason,
      globalCategory,
      patient
    ]
  );

  const mapSearchToAppointment = useCallback(
    () => dispatch(searchToAppointment(asPath)),
    [dispatch, asPath]
  );

  const cancelAppointment = useCallback(
    () => selectAppointment(null, null),
    [selectAppointment]
  );
  const confirmAppointment = useCallback(async () => {
    if (isIframe())
      return redirectToFullPage({
        _confirm: "1"
      });

    await mapSearchToAppointment();
  }, [mapSearchToAppointment, isIframe, redirectToFullPage]);

  const search = useCallback(() => dispatch(fetchSearchResults()), [dispatch]);

  return {
    ...v,
    ...s,
    resetState,
    search,
    isSearching,
    selectAppointment,
    getSelectedTermInfo,
    confirmAppointment,
    cancelAppointment,
    mapSearchToAppointment,
    canSwitchStage: (stage?: SearchStage) =>
      searchStageCheckers[stage ?? ((s.stage + 1) as SearchStage)](v.state)
  };
};

export const useSearchMiddleware = () => {
  const search = useSearch();

  // useAgeCalculation(search);
  useSearchParams(search);

  return search;
};

type UseSearch = ReturnType<typeof useSearch>;
export type SearchMiddleware = (search: UseSearch) => void;
