import get from "lodash/get";
import set from "lodash/set";
import values from "lodash/values";
import keys from "lodash/keys";
import mapValues from "lodash/mapValues";
import mapKeys from "lodash/mapKeys";
import forIn from "lodash/forIn";
import find from "lodash/find";
import pick from "lodash/pick";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import uniqBy from "lodash/uniqBy";
import max from "lodash/max";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import isPlainObject from "lodash/isPlainObject";
import isNil from "lodash/isNil";
import reduce from "lodash/reduce";
import uniqueId from "lodash/uniqueId";

const lodash = {
  get,
  set,
  values,
  keys,
  mapValues,
  mapKeys,
  forIn,
  find,
  pick,
  omit,
  omitBy,
  uniqBy,
  max,
  isEqual,
  isObject,
  isPlainObject,
  isNil,
  reduce,
  uniqueId
};

export default lodash;
