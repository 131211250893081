var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3PJlIgSPIG5BmISS6-lmy"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init } from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
// const SENTRY_TOKEN =
//     process.env.SENTRY_TOKEN || process.env.NEXT_PUBLIC_SENTRY_TOKEN || "";
// const SENTRY_TOKEN_HEADER =
//     process.env.SENTRY_TOKEN_HEADER || process.env.NEXT_PUBLIC_SENTRY_TOKEN_HEADER || "X-Sentry-Token";

init({
  dsn: SENTRY_DSN,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source
  autoSessionTracking: process.env.NEXT_PUBLIC_SENTRY_ENVELOPE === "true"
});
