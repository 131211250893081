import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction
} from "@reduxjs/toolkit";

import { getStageReducer, getValueReducer } from "../util";
import initialState from "./init";
import { appointmentStageCheckers } from "./stages";
import {
  answerPreRegQuestionnaire,
  answerPreScreening,
  AsyncThunkError,
  createAppointment,
  fetchPreRegQuestionnaireList,
  fetchPreScreening,
  getConsents,
  getPreRegQuestionnaire,
  setInsurance,
  verifyInsurance
} from "../thunks";
import { AppointmentStage, PaymentType } from "@/types";
import { appointmentToSearch } from "@/store/actions";
import { LOCAL_KEYS } from "@/constants";
// import { LOCAL_KEYS } from "@/constants";

export const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    ...getValueReducer<typeof initialState>(),
    ...getStageReducer(appointmentStageCheckers),
    setError: (
      state,
      action: PayloadAction<{
        message: string;
        code: number | null;
        action: string;
      }>
    ) => {
      state.errorMessage = action.payload.message;
      state.errorAction = action.payload.action ?? "";
      state.errorCode = action.payload.code;
    }
  },
  extraReducers: (builder) => {
    const asyncThunks: any = [
      // fetchEstablished,
      fetchPreRegQuestionnaireList,
      getPreRegQuestionnaire,
      answerPreRegQuestionnaire,
      setInsurance,
      verifyInsurance,
      fetchPreScreening,
      answerPreScreening,
      getConsents,
      createAppointment
    ];

    builder.addCase(appointmentToSearch, (state) => {
      state.stage = AppointmentStage.SEARCH;
    });

    builder.addCase(setInsurance.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.insurance = action.meta.arg; // ?? initialState.insurance;
    });

    builder.addCase(fetchPreScreening.fulfilled, (state, action) => {
      state.preScreeningData = action.payload;
    });

    builder.addCase(verifyInsurance.fulfilled, (state, action) => {
      state.insuranceCostsData = action.payload;
    });

    builder.addCase(verifyInsurance.rejected, () => {
      // state.stage = AppointmentStage.INSURANCE;
    });

    builder.addCase(fetchPreRegQuestionnaireList.pending, (state) => {
      state.preRegQuestionnaireList = [];
    });
    builder.addCase(fetchPreRegQuestionnaireList.fulfilled, (state, action) => {
      if (action.payload.results.length === 0) {
        state.stage = AppointmentStage.QUESTIONS + 1;
        return state;
      }

      action.payload.results.forEach((q) => {
        if (!state.preRegQuestionnaires[q.id]) {
          state.preRegQuestionnaires[q.id] = {
            name: q.name,
            questionnaire: null,
            answers: {},
            activeIndex: 0,
            finished: false
          };
        }
      });

      if (action.payload.results.length === 1)
        state.preRegQuestionnaireActive = action.payload.results[0].id;

      state.preRegQuestionnaireList = action.payload.results;
    });

    builder.addCase(getPreRegQuestionnaire.fulfilled, (state, action) => {
      state.preRegQuestionnaires[action.meta.arg].questionnaire =
        action.payload;
    });

    builder.addCase(answerPreRegQuestionnaire.fulfilled, (state, action) => {
      state.preRegQuestionnaires[action.meta.arg].finished = true;
    });

    builder.addCase(getConsents.fulfilled, (state, action) => {
      state.consentsData = action.payload;
      state.consents.healthixConsent.allow =
        action.payload.consents.healthixConsent?.currentStatus ?? false;
      state.consents.paymentType =
        state.consentsData.paymentTypesAvailable.includes(PaymentType.SAME_CARD)
          ? PaymentType.SAME_CARD
          : PaymentType.NEW_CARD;
      state.consents.authorizationConsent.ccEmail =
        state.consentsData.ccEmail || "";
    });

    builder.addCase(createAppointment.fulfilled, (state, action) => {
      if (action.payload !== "") {
        state.payment.clientSecret = action.payload;
        state.stage = AppointmentStage.PAYMENT;
      } else {
        state.stage = AppointmentStage.DONE;
        delete sessionStorage[LOCAL_KEYS.APPOINTMENT];
      }
    });
    builder.addCase(createAppointment.rejected, (state) => {
      state.payment.clientSecret = "";
    });

    builder.addMatcher(isRejected(...asyncThunks), (state, action) => {
      const error: AsyncThunkError | undefined = action.payload ?? action.error;
      state.errorMessage = error?.message ?? "";
      state.errorAction = action.type;
      if (error?.code) state.errorCode = error.code;
      state.loading = false;
    });
    builder.addMatcher(isPending(...asyncThunks), (state) => {
      state.errorMessage = "";
      state.errorAction = "";
      state.errorCode = null;
      state.loading = true;
    });
    builder.addMatcher(isFulfilled(...asyncThunks), (state) => {
      state.errorMessage = "";
      state.errorAction = "";
      state.errorCode = null;
      state.loading = false;
    });
  }
});
