import {
  MOCK_AGE_GROUPS,
  MOCK_ETHNICITY,
  MOCK_LANGUAGES,
  MOCK_RACES
} from "../mock";
import customFetch from "./customFetch";
import APIConverters from "./converters";

import {
  APIInterface,
  APIInterfaceBE,
  BE_Appointment,
  Patient,
  Practice
} from "@/types";
import { captureException } from "@sentry/nextjs";

export * from "./mapping";
export * from "./converters";

export const API_URL = process.env.NEXT_PUBLIC_API_URL ?? "";
const API_URL_SSR = process.env.SSR_API_URL ?? "";

const createApiBE: (baseUrl: string) => APIInterfaceBE = (baseUrl) => ({
  preRegistration: {
    list: (body) =>
      customFetch(
        "/pre-registration/questionnaire/list",
        { body, method: "POST" },
        baseUrl
      ),
    get: (body) =>
      customFetch(
        "/pre-registration/questionnaire",
        { body, method: "POST" },
        baseUrl
      ),
    save: (body) =>
      customFetch(
        "/pre-registration/questionnaire",
        { body, method: "PUT" },
        baseUrl
      )
  },
  preScreening: {
    get: (body) =>
      customFetch(
        "/pre-appointment-screening/questionnaire",
        {
          body,
          method: "POST"
        },
        baseUrl
      ),
    save: (body) =>
      customFetch(
        "/pre-appointment-screening/questionnaire",
        {
          body,
          method: "PUT"
        },
        baseUrl
      ),
    results: (body) =>
      customFetch(
        "/pre-appointment-screening/questionnaire/results",
        {
          body,
          method: "POST"
        },
        baseUrl
      )
  },
  search: {
    fetchOptions1: (body) =>
      customFetch(
        "/search/first",
        {
          body,
          method: "POST",
          fallback: {
            genders: [],
            patients: [],
            globalCategories: [],
            visitTypes: [],
            specialties: [],
            reasons: []
          }
        },
        baseUrl
      ),
    fetchOptions2: (body) =>
      customFetch(
        "/search/second",
        {
          body,
          method: "POST",
          fallback: {
            doctors: [],
            insurances: [],
            locations: []
          }
        },
        baseUrl
      ),
    fetchResults: (body) =>
      customFetch(
        "/search",
        {
          body,
          method: "POST",
          fallback: { results: [] }
        },
        baseUrl
      )
  },
  insurance: {
    set: (body) =>
      customFetch("/insurance/set", { body, method: "POST" }, baseUrl),
    confirm: (body) =>
      customFetch("/insurance/confirm", { body, method: "POST" }, baseUrl),
    checkNewPatient: (body) =>
      customFetch(
        "/insurance/check-new-patient",
        { body, method: "POST" },
        baseUrl
      ),
    verify: (body) =>
      customFetch("/insurance/check-result", { body, method: "POST" }, baseUrl),
    list: (idProvider) =>
      customFetch(
        `/insurance/provider/${idProvider}`,
        { method: "GET", fallback: { results: [] } },
        baseUrl
      )
  },
  getConsents: (body) =>
    customFetch("/consent", { body, method: "POST" }, baseUrl),
  appointment: {
    createEstablished: (body) =>
      customFetch(
        "/appointment/established-patient",
        { body, method: "POST" },
        baseUrl
      ),
    createNew: (body) =>
      customFetch(
        `/appointment/new-patient/${
          body.patient === Patient.PEDIATRIC ? "pediatric" : "adult"
        }`,
        { body, method: "POST" },
        baseUrl
      ),
    get: (uuid) =>
      customFetch(`/appointment-detail/${uuid}`, { method: "GET" }, baseUrl),
    cancel: (uuid) =>
      customFetch(`/appointment-detail/${uuid}`, { method: "DELETE" }, baseUrl)
  },
  checkEstablished: (body) =>
    customFetch(
      "/patient/check-established",
      { body, method: "POST" },
      baseUrl
    ),
  constant: {
    fetchConstants: (body) =>
      customFetch(
        `/constants?patient=${body.patient ?? Patient.PEDIATRIC}`,
        {
          method: "GET",
          fallback: {
            childAgeGroups: MOCK_AGE_GROUPS,
            locations: [],
            specialties: [],
            doctors: [],
            insurances: []
          }
        },
        baseUrl
      ),
    fetchStatics: () =>
      customFetch(
        "/statics",
        {
          method: "GET",
          fallback: FALLBACK_STATICS
        },
        baseUrl
      )
  }
});

const API_BE = createApiBE(API_URL);
const API_BE_SSR = createApiBE(API_URL_SSR);

export const API: (isSSR?: boolean) => APIInterface = (isSSR = false) => ({
  preRegistration: {
    list: API_BE.preRegistration.list,
    save: API_BE.preRegistration.save,
    get: API_BE.preRegistration.get
  },
  preScreening: {
    get: API_BE.preScreening.get,
    save: API_BE.preScreening.save,
    results: API_BE.preScreening.results
  },
  search: {
    fetchOptions1: (isSSR ? API_BE_SSR : API_BE).search.fetchOptions1,
    fetchOptions2: (isSSR ? API_BE_SSR : API_BE).search.fetchOptions2,
    fetchResults: (isSSR ? API_BE_SSR : API_BE).search.fetchResults
  },
  insurance: {
    set: async (body) => {
      const isEstablished = body.state.practice === Practice.ESTABLISHED;
      const convertedBody =
        APIConverters.setInsuranceRequestToBeSetInsuranceRequest(
          body,
          isEstablished
        );

      return isEstablished
        ? API_BE.insurance.set(convertedBody as any)
        : API_BE.insurance.checkNewPatient(convertedBody as any);
    },
    confirmSet: API_BE.insurance.confirm,
    verify: API_BE.insurance.verify,
    list: API_BE.insurance.list
  },
  getConsents: API_BE.getConsents,
  appointment: {
    createEstablished: API_BE.appointment.createEstablished,
    createNew: API_BE.appointment.createNew
  },
  state: {
    checkEstablished: async (body) => {
      const response = await API_BE.checkEstablished({
        firstName: body.firstName,
        lastName: body.lastName,
        dateOfBirth: body.birthday!,
        patient: body.patient
      });
      return APIConverters.beEstablishedDataToEstablishedData(response);
    },
    bookAppointment: async (appointmentState) => {
      const beAppointment =
        APIConverters.appointmentToBeAppointment(appointmentState);

      try {
        const res: any =
          appointmentState.practice === Practice.ESTABLISHED
            ? await API_BE.appointment.createEstablished({
                ...(beAppointment as BE_Appointment),
                idPatient: appointmentState.idPatient!
              })
            : await API_BE.appointment.createNew(
                beAppointment as BE_Appointment
              );
        const clientSecret =
          res.hasOwnProperty("clientSecret") && res["clientSecret"] !== null
            ? res["clientSecret"]
            : "";
        return {
          error: "",
          clientSecret
        };
      } catch (e: any) {
        captureException(e);
        return {
          error: e?.message || "An error occurred during appointment creation.",
          clientSecret: ""
        };
      }
    },
    getAppointment: (isSSR ? API_BE_SSR : API_BE).appointment.get,
    cancelAppointment: API_BE.appointment.cancel
  },
  fetchConstants: (isSSR ? API_BE_SSR : API_BE).constant.fetchConstants,
  fetchStatics: (isSSR ? API_BE_SSR : API_BE).constant.fetchStatics
});

const FALLBACK_STATICS = {
  session: {
    timeout: 30 * 60 * 1000
  },
  bounds: {
    adultMaxAge: 120,
    adultMinAge: 16,
    ageTolerance: (1 / 12 / 30) * 2,
    parentMaxAge: 120,
    parentMinAge: 18
  },
  contact: {
    languages: MOCK_LANGUAGES.map((i) => ({ value: i, text: i })),
    ethnicities: MOCK_ETHNICITY.map((i) => ({ value: i, text: i })),
    races: MOCK_RACES.map((i) => ({ value: i, text: i }))
  }
};
