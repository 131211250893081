import { ValidatorObject, ValidatorOpts } from "@/types";
import { MOCK_AREA_CODES, MOCK_STATES } from "@/constants/mock";

export const NAME_REGEX = /^[ a-zA-Z\-]+$/;
export const LAST_NAME_REGEX = /^[a-zA-Z]+-?\s?[a-zA-Z]*'?[a-zA-Z]+$/;
export const USA_PHONE_REGEX =
  /^1?\s*-?\s*(\d{3}|\(\s*\d{3}\s*\))\s*-?\s*\d{3}\s*-?\s*\d{4}\s?(x\d{0,9})?$/;
export const EMAIL_REGEX =
  // RFC2822
  // /[a-z\d!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z\d!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z\d](?:[a-z\d-]*[a-z\d])?\.)+[a-z\d](?:[a-z\d-]*[a-z\d])?/;
  // RFC2822 With Uppercase letters
  /[a-zA-Z\d!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z\d!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z\d](?:[a-zA-Z\d-]*[a-zA-Z\d])?\.)+[a-zA-Z\d](?:[a-zA-Z\d-]*[a-zA-Z\d])?/;

export const ZIP_REGEX = /^((\d{9})|((\d{5})(-\d{4})?))$/;

const STRING_MAX = 255;
const NAME_MAX = 50;
const PHONE_MAX = 35;
const EMAIL_MAX = 50;

const createCustomValidator = (
  inputName: string,
  opts: ValidatorOpts
): ValidatorObject => ({
  validator: (val) => {
    if (opts.required && !val) return "Required.";
    if (opts.pattern && !opts.pattern.test(val))
      return `Must be a valid ${inputName.toLowerCase()}.`;
    if (opts.maxLength && val.length > opts.maxLength)
      return `Must be less than ${opts.maxLength} characters long.`;
    if (opts.minLength && val.length < opts.minLength)
      return `Must be more than ${opts.minLength} characters long.`;
    if (opts.max && +val > opts.max) return `Must be lower than ${opts.max}.`;
    if (opts.min && +val < opts.min) return `Must be higher than ${opts.min}.`;
    return "";
  },
  ...opts
});

type ValidationFn<ARGS extends any[] = any[]> = (
  ...args: ARGS
) => ValidatorObject;
type Validation<T extends string | number | symbol = string> = Record<
  T,
  ValidatorObject | ValidationFn
> & {
  custom: typeof createCustomValidator;
  equals: ValidationFn<[string]>;
  name: ValidatorObject;
  lastName: ValidatorObject;
  address_state: ValidatorObject;
  phone: ValidatorObject;
  email: ValidatorObject;
  zip: ValidatorObject;
};

export const validation = {
  custom: createCustomValidator,
  equals: (value: string) => ({
    validator: (val) => {
      if (!val) return "Required.";
      if (val !== value) return `Must be equal to: '${value}'`;
      if (val.length > STRING_MAX)
        return `Must be less than ${STRING_MAX} characters long.`;
      return "";
    },
    required: true,
    maxLength: STRING_MAX,
    pattern: RegExp(`^${value}$`)
  }),
  address_state: {
    validator: (val) => {
      if (!val) return "Required.";
      if (!MOCK_STATES.includes(val)) return "Not a valid state.";
      return "";
    },
    required: true,
    minLength: 2,
    maxLength: 2
  },
  name: {
    validator: (val) => {
      if (!val) return "Required.";
      if (!NAME_REGEX.test(val)) return "Must be a valid name (letters-only)";
      if (val.length > STRING_MAX)
        return `Must be less than ${STRING_MAX} characters long.`;
      return "";
    },
    required: true,
    maxLength: NAME_MAX,
    pattern: NAME_REGEX
  },
  lastName: {
    validator: (val) => {
      if (!val) return "Required.";
      if (!LAST_NAME_REGEX.test(val)) return "Must be a valid last name";
      if (val.length > STRING_MAX)
        return `Must be less than ${STRING_MAX} characters long.`;
      return "";
    },
    required: true,
    maxLength: NAME_MAX,
    pattern: LAST_NAME_REGEX
  },
  phone: {
    validator: (val) => {
      if (!val) return "Required.";
      if (!USA_PHONE_REGEX.test(val)) return "Must be a valid phone number.";
      const res = USA_PHONE_REGEX.exec(val);
      if (!!res && res.length >= 1 && !MOCK_AREA_CODES.includes(res[1]))
        return "Not a valid area code.";
      if (val.length > STRING_MAX)
        return `Must be less than ${STRING_MAX} characters long.`;
      return "";
    },
    required: true,
    maxLength: PHONE_MAX,
    pattern: USA_PHONE_REGEX
  },
  email: createCustomValidator("E-mail", {
    required: true,
    maxLength: EMAIL_MAX,
    pattern: EMAIL_REGEX
  }),
  zip: createCustomValidator("Zip code", {
    required: true,
    maxLength: 10,
    pattern: ZIP_REGEX
  })
} as Validation;
