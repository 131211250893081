import moment from "moment-timezone";

import { TimeSlot } from "@/types";

const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const getMonthName = (d: moment.Moment) => d.format("MMMM");
export const getMonthNameInd = (ind: number) => MONTH_NAMES[ind];

export const dayth = (d: moment.Moment) => d.format("Do");

export const justDate = (d: moment.Moment) => d.startOf("day");

export const getToday = () => justDate(moment());
export const getTomorrow = () => getToday().add(1, "day");

export const stringToDate = (s: string) => justDate(moment(s, true));
export const dateToString = (d: moment.Moment) => d.format("YYYY-MM-DD");
export const dateNullToString = (d: moment.Moment | null): string | null =>
  !!d ? dateToString(d) : null;

export const isIsoDate = (str: string) => {
  return /\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}\.\d{3})?.*/.test(str);
};

export const daysOfMonth = (d: moment.Moment) => d.daysInMonth();

export const monthsBetween = (d1: moment.Moment, d2: moment.Moment) =>
  d1.diff(d2, "months", true);
// {
//   let months = (d2.getFullYear() - d1.getFullYear()) * 12;
//   months -= d1.getMonth();
//   months += d2.getMonth();
//   months += (d2.getDate() - d1.getDate()) / daysOfMonth(d2);
//   return months;
// };

export const addDays = (d: moment.Moment, days: number) => d.add(days, "days");

export const dateMinMaxForAge = (
  age: number,
  today = getToday()
): [moment.Moment, moment.Moment] => {
  const dateMin = moment(today);
  dateMin.subtract(age + 1, "year");
  dateMin.add(1, "day");
  const dateMax = moment(today);
  dateMax.subtract(age, "year");

  return [dateMin, dateMax];
};
export const dateMinMaxForAgeRange = (
  ageRange: [number, number]
): [moment.Moment, moment.Moment] => [
  dateMinMaxForAge(ageRange[1])[0],
  dateMinMaxForAge(ageRange[0])[1]
];

export const separateTimeGroups = (times: TimeSlot[]) => {
  const res: { AM: TimeSlot[]; PM: TimeSlot[]; Evening: TimeSlot[] } = {
    AM: [],
    PM: [],
    Evening: []
  };
  times.forEach((t) => {
    const time = t.startTime.split(":");
    const h = +time[0];
    // const m = +time[1];

    if (h < 12) res.AM.push(t);
    else if (h < 17) res.PM.push(t);
    else res.Evening.push(t);
  });
  return res;
};

export const displayTimeUSA = (time: string) => {
  if (time === "") return;
  const [h, m] = time.split(":");

  const isPM = +h >= 12;
  const hourPM = +h === 0 || +h === 12 ? 12 : +h % 12;
  return `${hourPM.toString()}:${m} ${isPM ? "PM" : "AM"}`;
};
