import { useCallback, useEffect, useState } from "react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import type { PaymentIntent } from "@stripe/stripe-js";
import { captureException } from "@sentry/nextjs";

let t: any = null;
const MESSAGE_SUCCESS = "Payment succeeded!";
const MESSAGE_TTL = 5000;
export const CLIENT_SECRET_QUERY_KEY = "payment_intent_client_secret";

export const usePayments = (
  fetchStatus?: boolean,
  clientSecret = "",
  redirect = process.env.NEXT_PUBLIC_HOST ?? ""
) => {
  const stripe = useStripe();
  const elements = useElements();

  const notInit = !stripe || !elements;
  const [intent, setIntent] = useState<PaymentIntent | undefined>();
  const intentSet = intent !== undefined;

  const [isLoading, setIsLoading] = useState(false);
  const [message, _setMessage] = useState("");
  const setMessage = useCallback(
    (msg: string) => {
      _setMessage(msg);
      if (t) clearTimeout(t);
      t = setTimeout(() => _setMessage(""), MESSAGE_TTL);
    },
    [_setMessage]
  );

  const isReady = !isLoading && !notInit;
  const submitPayment = useCallback(async () => {
    if (!isReady) return;
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: redirect
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    (error.type === "card_error" || error.type === "validation_error") &&
    !!error.message
      ? setMessage(error.message)
      : setMessage("An unexpected error occurred.");

    setIsLoading(false);
  }, [redirect, isReady, stripe, elements, setMessage]);

  const redirectToCheckout = useCallback(async () => {
    if (!stripe) return;
    await stripe.redirectToCheckout({
      sessionId: clientSecret
    });
  }, [stripe, clientSecret]);

  const getIntent = useCallback(
    async (cs = clientSecret) => {
      if (!stripe || !cs) return;

      const res = await stripe.retrievePaymentIntent(cs);
      return res.paymentIntent;
    },
    [stripe, clientSecret]
  );

  useEffect(() => {
    if (!fetchStatus) return;

    const csInParams = getClientSecretFromQuery();
    if (!csInParams || (!!clientSecret && clientSecret !== csInParams)) return;

    getIntent(csInParams).then((paymentIntent) => {
      if (!paymentIntent) return;
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(MESSAGE_SUCCESS);
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [fetchStatus, clientSecret, setMessage, getIntent]);

  useEffect(() => {
    if (!intentSet) getIntent().then(setIntent);
  }, [getIntent, intentSet]);

  const isSuccess = MESSAGE_SUCCESS === message;

  return {
    submitPayment,
    isLoading,
    message,
    getIntent,
    setMessage,
    notInit,
    isReady,
    isSuccess,
    intent,
    redirectToCheckout
  };
};

export const getClientSecretFromQuery = (
  query?: string | Record<string, string>
) =>
  new URLSearchParams(query ?? (window ? window.location.search : "")).get(
    CLIENT_SECRET_QUERY_KEY
  );

export const usePaymentRedirect = (
  clientSecret: string | null,
  onError?: (msg: string) => any,
  disabled?: boolean
) => {
  const isReady = !!clientSecret && !disabled;

  const redirect = useCallback(async (secrret: string) => {
    const stripe = await loadStripe(
      process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY ?? ""
    );
    if (!stripe) throw new Error("Could not load stripe.");

    if (!secrret) throw new Error("No client secret.");

    const { error } = await stripe.redirectToCheckout({
      sessionId: secrret
    });
    if (error)
      throw new Error(error.message ?? "Unknown Stripe error occurred.");
  }, []);

  useEffect(() => {
    if (!isReady) return;

    redirect(clientSecret).catch((err) => {
      captureException(err);
      if (onError) onError(err?.message ?? "Unknown error.");
    });
  }, [isReady, redirect, clientSecret, onError]);
};
