import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import initialState from "./init";
import { Dialog } from "@/types";

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    registerDialog: (
      state,
      action: PayloadAction<{ index: string; dialog: Dialog }>
    ) => {
      const i = action.payload.index;
      state.dialogs[i] = action.payload.dialog;
    },
    unregisterDialog: (state, action: PayloadAction<string>) => {
      const i = action.payload;
      delete state.dialogs[i];
    },
    setMessage: (
      state,
      action: PayloadAction<{ index: string; message: string }>
    ) => {
      state.dialogs[action.payload.index].message = action.payload.message;
    },
    showDialog: (state, action: PayloadAction<string>) => {
      if (!state.dialogs[action.payload]) return state;
      state.dialogs[action.payload].open = true;
      if (state.showId === null) state.showId = action.payload;
    },
    showFirstOpenDialog: (state) => {
      for (let i in Object.keys(state.dialogs)) {
        if (state.dialogs[i] && state.dialogs[i].open) {
          state.showId = i;
          break;
        }
      }
    },
    hideDialog: (state, action: PayloadAction<string>) => {
      if (state.dialogs[action.payload])
        state.dialogs[action.payload].open = false;
      state.showId = null;
    }
  }
});
