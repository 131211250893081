import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import { getStageReducer, getValueReducer } from "../util";
import initialState from "./init";
import { searchStageCheckers } from "./stages";
import {
  fetchConstants,
  fetchOptions1,
  fetchOptions2,
  fetchSearchResults
} from "../thunks";
import { SearchStage } from "@/types";
import { appointmentToSearch, searchToAppointment } from "@/store/actions";

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    ...getValueReducer<typeof initialState>(),
    ...getStageReducer(searchStageCheckers)
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOptions1.pending, (state) => {
      state.isFetching1 = true;
    });
    builder.addCase(fetchOptions1.fulfilled, (state) => {
      state.isFetching1 = false;
    });
    builder.addCase(fetchOptions1.rejected, (state) => {
      state.isFetching1 = false;
    });

    builder.addCase(fetchOptions2.pending, (state) => {
      state.isFetching2 = true;
    });
    builder.addCase(fetchOptions2.fulfilled, (state) => {
      state.isFetching2 = false;
    });
    builder.addCase(fetchOptions2.rejected, (state) => {
      state.isFetching2 = false;
    });

    builder.addCase(fetchConstants.pending, (state) => {
      state.isFetching1 = true;
      state.isFetching2 = true;
    });
    builder.addCase(fetchConstants.fulfilled, (state) => {
      state.isFetching1 = false;
      state.isFetching2 = false;
    });
    builder.addCase(fetchConstants.rejected, (state) => {
      state.isFetching1 = false;
      state.isFetching2 = false;
    });

    builder.addCase(fetchSearchResults.pending, (state) => {
      state.isSearching = true;
      state.stage = SearchStage.RESULTS;
      state.previousDate = state.search2.date;
      state.results = [];
    });
    builder.addCase(fetchSearchResults.fulfilled, (state, action) => {
      state.isSearching = false;
      state.results = action.payload;
      state.search2.date = action.payload[0]?.date ?? state.search2.date;
    });
    builder.addCase(fetchSearchResults.rejected, (state) => {
      state.isSearching = false;
    });

    builder.addMatcher(
      isAnyOf(searchToAppointment, appointmentToSearch),
      (state) => {
        state.selectedResult = null;
        state.selectedTime = null;
      }
    );
  }
});
