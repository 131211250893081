import { isNotNull } from "@/helpers";
import { Patient, SearchStage, SearchState } from "@/types";

export const searchStageCheckers: Record<
  SearchStage,
  (state: SearchState) => boolean
> = {
  [SearchStage.SEARCH1]: (state) =>
    !searchStageCheckers[SearchStage.SEARCH2](state),
  [SearchStage.SEARCH2]: (state) =>
    // state.stage === SearchStage.SEARCH1 &&
    // isNotNull(state.age) &&
    isNotNull(
      state.patient === Patient.PEDIATRIC ? state.birthday : state.age
    ) && isNotNull(state.search1),
  [SearchStage.RESULTS]: (state) =>
    state.stage === SearchStage.SEARCH2 &&
    // isNotNull(state.age) &&
    isNotNull(
      state.patient === Patient.PEDIATRIC ? state.birthday : state.age
    ) &&
    isNotNull(state.search2.date),
  [SearchStage.RESULTS + 1]: () => false
};
