import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { dataSlice } from "./data/slice";
import { searchSlice } from "./search/slice";
import { appointmentSlice } from "./appointment/slice";
import { dialogSlice } from "./dialog/slice";

import { searchMiddleware } from "./search/middleware";
import { appointmentMiddleware } from "./appointment/middleware";

export const appStore = configureStore({
  reducer: {
    data: dataSlice.reducer,
    search: searchSlice.reducer,
    appointment: appointmentSlice.reducer,
    dialog: dialogSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(searchMiddleware.middleware)
      .prepend(appointmentMiddleware.middleware)
});

export type AppState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
