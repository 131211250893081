import { useCallback, useEffect, useState } from "react";

type StopAtFn<El extends any> = (el: El, firstEl: El) => boolean;

const indexResults = <T extends any>(
  index: number,
  results: T[],
  stopAt: StopAtFn<T> = () => false
): [T[], number] => {
  if (results.length === 0) return [results, 0];

  const partial: T[] = [];
  let i = index;
  while (i < results.length && !stopAt(results[i], results[index])) {
    partial.push(results[i]);
    i++;
  }
  return [partial, i];
};
const partialResults = <T extends any>(
  start: number,
  end: number,
  results: T[]
): [T[], number] => [results.slice(start, end), end];

export const useIndexedPagination = <T extends any>(
  allResults: T[],
  stopCriteria?: StopAtFn<T>
) => {
  const [activePage, setActivePage] = useState(0);

  const [indexes, setIndexes] = useState<number[]>([0]);
  const lastIndexed = indexes[indexes.length - 1];
  const indexStart = indexes[activePage];
  const indexStartNext = indexes[activePage + 1];

  const [displayResults, indexEnd] =
    indexStartNext !== undefined
      ? partialResults(indexStart, indexStartNext, allResults)
      : indexResults(indexStart, allResults, stopCriteria);

  useEffect(() => {
    if (indexEnd > lastIndexed) setIndexes((i) => [...i, indexEnd]);
  }, [indexEnd, lastIndexed]);

  const hasRight = indexEnd <= allResults.length - 1;
  const goRight = useCallback(() => {
    if (hasRight) setActivePage((a) => a + 1);
  }, [hasRight]);

  const hasLeft = indexStart > 0;
  const goLeft = useCallback(() => {
    if (hasLeft) setActivePage((a) => a - 1);
  }, [hasLeft]);

  return {
    displayResults,
    goLeft,
    hasLeft,
    goRight,
    hasRight,
    indexStart,
    indexEnd,
    activePage
  };
};
