import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import _ from "@/lodash";

import { appointmentSlice } from "./slice";
import {
  answerPreRegQuestionnaire,
  fetchConstants,
  fetchPreRegQuestionnaireList,
  fetchPreScreening,
  getConsents,
  verifyInsurance
} from "../thunks";
import { loadSearchFromParams, searchToAppointment } from "../actions";
import { AppointmentMiddleware, useAppDispatch } from "@/store";
import {
  AppointmentStage,
  AppointmentState,
  AppStateDef,
  Patient,
  SearchState
} from "@/types";
import {
  convertUsingMappig,
  extractRouteAndParams,
  isNotNull
} from "@/helpers";
import { IS_PAYMENT_EXTERNAL, LOCAL_KEYS } from "@/constants";

export const appointmentMiddleware = createListenerMiddleware<AppStateDef>();

// MAP SEARCH TO APPOINTMENT
appointmentMiddleware.startListening({
  actionCreator: searchToAppointment,
  effect: (action, listenerApi) => {
    const state = listenerApi.getOriginalState();

    if (!state.search.selectedTime) return;
    if (!state.search.selectedResult) return;

    const partialState = convertUsingMappig<SearchState, AppointmentState>(
      state.search,
      action.payload.searchToAppointmentMap
    );
    _.set(
      partialState,
      "searchParams",
      extractRouteAndParams(action.payload.urlWithParams).paramsStr
    );

    listenerApi.dispatch(
      appointmentSlice.actions.setPartialState(partialState)
    );
    listenerApi.dispatch(appointmentSlice.actions.switchStage(null));
  }
});

// PAYMENT STAGE
appointmentMiddleware.startListening({
  matcher: isAnyOf(
    appointmentSlice.actions.set,
    appointmentSlice.actions.setState,
    appointmentSlice.actions.setPartialState
  ),
  effect: (action, listenerApi) => {
    // const appointmentState = listenerApi.getOriginalState().appointment;
    const newAppointmentState = listenerApi.getState().appointment;
    const secret = newAppointmentState.payment.clientSecret;
    if (
      newAppointmentState.stage !== AppointmentStage.PAYMENT &&
      secret !== "" &&
      !!secret
    ) {
      if (IS_PAYMENT_EXTERNAL)
        sessionStorage.removeItem(LOCAL_KEYS.APPOINTMENT);

      listenerApi.dispatch(
        appointmentSlice.actions.switchStage(AppointmentStage.PAYMENT)
      );
    }
  }
});

// SAVE ANSWERS TO STATE
appointmentMiddleware.startListening({
  actionCreator: answerPreRegQuestionnaire.fulfilled,
  effect: (_, listenerApi) => {
    listenerApi.dispatch(
      appointmentSlice.actions.saveToStorage({ key: LOCAL_KEYS.APPOINTMENT })
    );
  }
});

// ON SWITCH STAGE
appointmentMiddleware.startListening({
  matcher: isAnyOf(
    appointmentSlice.actions.switchStage,
    appointmentSlice.actions.setState
  ),
  effect: (_, listenerApi) => {
    const appointmentState = listenerApi.getOriginalState().appointment;
    const newAppointmentState = listenerApi.getState().appointment;

    if (appointmentState.stage !== newAppointmentState.stage) {
      listenerApi.dispatch(
        appointmentSlice.actions.set({ key: "loading", value: false })
      );
      newAppointmentState.stage === AppointmentStage.DONE
        ? delete sessionStorage[LOCAL_KEYS.APPOINTMENT]
        : listenerApi.dispatch(
            appointmentSlice.actions.saveToStorage({
              key: LOCAL_KEYS.APPOINTMENT
            })
          );

      if (newAppointmentState.stage === AppointmentStage.PRE_SCREENING) {
        listenerApi.dispatch(fetchPreScreening());
      }
      if (newAppointmentState.stage === AppointmentStage.COSTS1) {
        listenerApi.dispatch(verifyInsurance());
      }
      if (newAppointmentState.stage === AppointmentStage.QUESTIONS) {
        listenerApi.dispatch(fetchPreRegQuestionnaireList());
      }
      if (newAppointmentState.stage === AppointmentStage.COSTS2) {
        listenerApi.dispatch(getConsents());
      }

      const isChild = appointmentState.patient === Patient.PEDIATRIC;
      const { firstName, lastName } = isChild
        ? appointmentState.parent_info
        : appointmentState.info;

      if (
        newAppointmentState.stage === AppointmentStage.INSURANCE &&
        !isNotNull(appointmentState.insurance?.holder)
      ) {
        listenerApi.dispatch(
          appointmentSlice.actions.set({
            key: "insurance.holder",
            value: { firstName, lastName, birthday: null, gender: null }
          })
        );
      }
    }
  }
});

// ON INIT, ONCE
export const useInitAppointment: AppointmentMiddleware = (app) => {
  const dispatch = useAppDispatch();
  const {
    stage,
    state: { patient },
    updateState
  } = app;

  const [init, setInit] = useState(false);
  const isReady = stage > AppointmentStage.SEARCH;

  useEffect(() => {
    if (!isReady) return setInit(false);
    if (init) return;

    // Load Search State (from Appointment State)
    dispatch(loadSearchFromParams(null));
    dispatch(fetchConstants(patient));

    setInit(true);
  }, [dispatch, init, isReady, patient, updateState]);
};

export const useRedirectToAppointment: AppointmentMiddleware = (app) => {
  const isAppointmentStage = app.stage === AppointmentStage.SEARCH + 1;
  const router = useRouter();

  const shouldRedirect =
    isAppointmentStage && router.pathname !== "/appointment";

  useEffect(() => {
    if (shouldRedirect) {
      setTimeout(() => {
        const url = new URL(window.location.href);
        url.search = "";
        url.pathname = "appointment";
        window.location.href = url.toString();
      });
    }
  }, [shouldRedirect]);
};

export const useResetQuestionnaire: AppointmentMiddleware = (app) => {
  const token = app.state.token;
  const idTimeSlot = app.state.idTimeSlot;
  const valueSet = app.valueSet;

  useEffect(() => {
    valueSet("preRegQuestionnaires", {});
  }, [token, idTimeSlot, valueSet]);
};
