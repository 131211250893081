import { ReactNode } from "react";
import moment from "moment-timezone";

import { API_URL } from "@/constants/api";
import { BE_ConsentsResponse, Patient } from "@/types";

type ConsentData = {
  text: ReactNode;
  content: ReactNode | ((props: any) => JSX.Element) | null;
};

export const CONSENTS: (
  data: BE_ConsentsResponse,
  patient: Patient,
  token: string,
  healthix: boolean
) => Record<keyof BE_ConsentsResponse["consents"], ConsentData> = (
  data,
  patient,
  token,
  healthix
) => ({
  generalConsent: {
    text: (
      <>
        agree to the General Registration, Privacy, Communication, Payment and
        Treatment Consent -{" "}
        <a
          href={`${API_URL}/consent-preview/general?appType=${patient}&token=${token}`}
          rel="noreferrer"
          target="_blank"
          className="consent-details"
        >
          show consent
        </a>
      </>
    ),
    content: null
  },
  paymentConsent: {
    text: (
      <>
        I agree to pay a copay of <b>{data.copayment.toFixed(2)} dollars</b> for
        the upcoming visit and
        <br />
        an outstanding balance{" "}
        <b>{data.outstandingBalance.amount.toFixed(2)} dollars</b> for previous
        services.
        <br />
        Total Amount charged to your credit card will be{" "}
        <b>
          {(data.copayment + data.outstandingBalance.amount).toFixed(2)} dollars
        </b>
        .
      </>
    ),
    content:
      data.outstandingBalance.outstandingBalanceList.length === 0 &&
      data.consents.paymentConsent.costEstimatesInfo.length === 0 ? null : (
        <>
          <h3>Details about your balance:</h3>
          {data.outstandingBalance.outstandingBalanceList.map((ob, i) => (
            <table key={i} className="table-outstandingBalance">
              <thead>
                <tr>
                  <th colSpan={2}>
                    {moment(ob.dateOfService).toString()} - {ob.provider}
                  </th>
                </tr>
              </thead>
              <tbody>
                {ob.items.map((obi, j) => (
                  <tr key={j}>
                    <td>{obi.comment}</td>
                    <td>${obi.amount.toFixed(2)}</td>
                  </tr>
                ))}
                <tr>
                  <td>Total:</td>
                  <td>${ob.totalAmount.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Paid:</td>
                  <td>${ob.paidAmount.toFixed(2)}</td>
                </tr>
                <tr>
                  <td>Unpaid:</td>
                  <td>${ob.unpaidAmount.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          ))}
          <p>
            I also understand and that my{" "}
            {patient === Patient.PEDIATRIC && "child's"} visit will include, but
            may not be limited to, the following services, the estimated charges
            for which are listed below.
            <br />
            <br />I authorize you to charge my credit card for any of those
            amounts I am obligated to pay you for services rendered to{" "}
            {patient === Patient.PEDIATRIC ? "my child" : "me"} at the
            check-out, after the completion of the service.
          </p>
          <h3>
            Cost estimates for your {patient === Patient.PEDIATRIC && "child's"}{" "}
            visit for the commonly provided services:
          </h3>
          <table className="table-costEstimate">
            <tbody>
              {data.consents.paymentConsent.costEstimatesInfo.map((ce, i) => (
                <tr key={i}>
                  <td>{ce.text}</td>
                  <td>${ce.price.toFixed(2)}</td>
                </tr>
              ))}
              <tr>
                <td>Total Cost estimate for the visit:</td>
                <td>
                  $
                  {data.consents.paymentConsent.costEstimatesInfo
                    .reduce((prev, curr) => prev + curr.price, 0)
                    .toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
          <p>
            Final costs will be determined based on the services provided at the
            check-out.
          </p>
        </>
      )
  },
  authorizationConsent: {
    text: (
      <>
        I authorize you to charge my credit card for any outstanding balance not
        paid by my insurance. -{" "}
        <a
          href={`${API_URL}/consent-preview/authorization-for-payment?appType=${patient}&token=${token}`}
          rel="noreferrer"
          target="_blank"
          className="consent-details"
        >
          print consent
        </a>
      </>
    ),
    content: ""
  },
  healthixConsent: {
    text: (
      <>
        NYS health information exchange platform access - Healthix Consent. -{" "}
        <a
          href={`${API_URL}/consent-preview/healthix?appType=${patient}&token=${token}&agree=${healthix}`}
          rel="noreferrer"
          target="_blank"
          className="consent-details"
        >
          print consent
        </a>
      </>
    ),
    content: ""
  },
  telemedicineConsent: {
    text: (
      <>
        I agree to the telemedicine consent. -{" "}
        <a
          href={`${API_URL}/consent-preview/telemedicine?appType=${patient}&token=${token}`}
          rel="noreferrer"
          target="_blank"
          className="consent-details"
        >
          print consent
        </a>
      </>
    ),
    content: ""
  }
});
