import _ from "@/lodash";

import {
  APPOINTMENT_BE_TO_BE_MISSING_DATA,
  APPOINTMENT_TO_APPOINTMENT_BE_MAP,
  APPOINTMENT_TO_BE_INSURANCE_REQUEST_MAP,
  APPOINTMENT_TO_BE_PATIENT_INFO,
  BE_ESTABLISHED_DATA_TO_ESTABLISHED_DATA_MAP
} from "./mapping";

import { convertUsingMappig, stringToDate } from "@/helpers";
import {
  APIConverter,
  AppointmentEstablishedData,
  AppointmentState,
  BE_API_CheckEstablished,
  BE_Appointment,
  BE_Established,
  BE_InsuranceRequest,
  BE_InsuranceRequestBase,
  BE_MissingData,
  BE_PatientInfo,
  InsuranceSet,
  Patient,
  Practice
} from "@/types";

const _setBirthday = (obj: any, path: string, birthday: string) => {
  const date = stringToDate(birthday);
  _.set(obj, `${path}.yearOfBirth`, date.year());
  _.set(obj, `${path}.monthOfBirth`, date.month() + 1);
  _.set(obj, `${path}.dayOfBirth`, date.date());
};

const setInsuranceRequestToBeSetInsuranceRequest: APIConverter<
  Parameters<InsuranceSet>[0],
  BE_InsuranceRequestBase & BE_Established
> = (body, established: boolean): any => {
  let obj: any = convertUsingMappig<
    AppointmentState,
    BE_InsuranceRequestBase & BE_Established
  >(body.state, APPOINTMENT_TO_BE_INSURANCE_REQUEST_MAP);

  if (!established) {
    obj.patientInfo = convertUsingMappig<AppointmentState, BE_PatientInfo>(
      body.state,
      APPOINTMENT_TO_BE_PATIENT_INFO
    );
    _setBirthday(obj, "patientInfo", body.state.info.birthday!);
  }
  obj.insurance = body.newInsurance
    ? ({
        id: body.newInsurance.id,
        name: body.newInsurance.name,
        code: body.newInsurance.number
      } as BE_InsuranceRequest)
    : null;

  return obj;
};

const beEstablishedDataToEstablishedData: APIConverter<
  Awaited<ReturnType<BE_API_CheckEstablished>>,
  AppointmentEstablishedData
> = (beEstablishedData) => {
  const establishedData = convertUsingMappig<
    Awaited<ReturnType<BE_API_CheckEstablished>>,
    AppointmentEstablishedData
  >(beEstablishedData, BE_ESTABLISHED_DATA_TO_ESTABLISHED_DATA_MAP);
  establishedData.results = true;
  return establishedData as AppointmentEstablishedData;
};

const appointmentToBeAppointment: APIConverter<
  AppointmentState,
  BE_Appointment
> = (appointmentState) => {
  const beAppointment = convertUsingMappig<AppointmentState, BE_Appointment>(
    appointmentState,
    APPOINTMENT_TO_APPOINTMENT_BE_MAP
  );

  if (appointmentState.practice === Practice.NEW) {
    _.set(beAppointment, "patientInfo.phoneNumber.type", "HOME");
    _.set(beAppointment, "patientInfo.middleName", "");
    _setBirthday(beAppointment, "patientInfo", appointmentState.info.birthday!);
    if (!beAppointment.patientInfo?.insurance)
      _.set(beAppointment, "patientInfo.insurance", null);

    if (appointmentState.patient === Patient.PEDIATRIC) {
      if (appointmentState.parent_info.birthday!)
        _setBirthday(
          beAppointment,
          "patientInfo.mother",
          appointmentState.parent_info.birthday!
        );
      if (appointmentState.insurance?.holder?.birthday)
        _setBirthday(
          beAppointment,
          "patientInfo.pph",
          appointmentState.insurance?.holder?.birthday!
        );
      if (!beAppointment.patientInfo?.insurance)
        _.set(beAppointment, "patientInfo.pph", null);
    } else {
      _.set(beAppointment, "patientInfo.pph", undefined);
      _.set(beAppointment, "patientInfo.mother", undefined);
    }
  }

  if (appointmentState.practice === Practice.ESTABLISHED) {
    _.set(beAppointment, "patientInfo", {});
    _.set(
      beAppointment,
      "patientInfo.updatedMissingPatientData",
      convertUsingMappig<BE_Appointment, BE_MissingData>(
        beAppointment as any,
        APPOINTMENT_BE_TO_BE_MISSING_DATA,
        (el, _, newPath) =>
          appointmentState.contactMissingData[newPath] ? el : undefined
      ) as any
    );
  }

  if (appointmentState.visit.location === null) beAppointment.location = null;

  // beAppointment.consents = _.omitBy(
  //   appointmentState.consents,
  //   (val, key) => !(appointmentState.consentsData?.consents as any)[key]
  // ) as any;

  return beAppointment as BE_Appointment;
};

const APIConverters = {
  appointmentToBeAppointment,
  beEstablishedDataToEstablishedData,
  setInsuranceRequestToBeSetInsuranceRequest
};
export default APIConverters;
