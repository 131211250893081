import "../styles/globals.scss";
import "swiper/css";
import type { AppProps } from "next/app";
import moment from "moment-timezone";

import { PopupPlace } from "@/components/UI/Popup";

import { AppStoreProvider } from "@/store";

Date.prototype.toString = function () {
  return this.toLocaleDateString(process.env.NEXT_PUBLIC_LOCALE || "en-US");
};
moment.tz.setDefault("America/New_York");
moment.prototype.toString = function () {
  return (this as moment.Moment)
    .toDate()
    .toLocaleDateString(process.env.NEXT_PUBLIC_LOCALE || "en-US");
};

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <AppStoreProvider>
      <PopupPlace />
      <Component {...pageProps} />
    </AppStoreProvider>
  );
}

export default MyApp;
