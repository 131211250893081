import { handleError } from "@/helpers";

const DEFAULT_HEADERS: HeadersInit = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

type FetchOpts<Req extends any, Res> = {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  body?: Req;
  fallback?: Res;
  headers?: HeadersInit;
};

const safeJson = async (response: Response) => {
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return await response.json();
  }
  return {};
};

export class FetchError extends Error {
  public statusCode: number;
  constructor(message: string, statusCode: number = 500) {
    super(message);
    this.statusCode = statusCode;
    this.name = "FetchError";
  }
}

const throwErrorMessage = (message?: string, status = 500) => {
  throw new FetchError(message || `An error has occurred (${status})`, status);
};

const customFetch = async <Req extends any, Res = any>(
  url: string,
  opts: FetchOpts<Req, Res>,
  baseUrl?: string
): Promise<Res> => {
  const method = opts.method ?? "GET";

  let failReason = "";

  try {
    const res = await fetch(baseUrl + url, {
      method,
      body: method !== "GET" ? JSON.stringify(opts.body ?? {}) : null,
      headers: { ...DEFAULT_HEADERS, ...opts.headers }
    });
    const resData = await safeJson(res);
    if (!res.ok) {
      failReason = `Fetched. Status not OK! (${res.status}) [${url}]`;
      throwErrorMessage(
        resData.userMessage || resData.message || resData.error,
        res.status
      );
    }

    return resData as Res;
  } catch (e: Error | FetchError | any) {
    failReason =
      failReason ??
      (opts.fallback
        ? "Fallback value used for request: " + baseUrl + url
        : "Unknown error! Fetch API failed...");
    return handleError(e, opts.fallback);
  } finally {
    if (failReason) console.warn(failReason);
  }
};

export default customFetch;
