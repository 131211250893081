import { ChildAgeGroup } from "@/types";

export const MOCK_RACES: string[] = [
  "American Indian or Alaska Native",
  "Asian",
  "Black or African American",
  "Native Hawaiian or Other Pacific Islander",
  "White",
  "Other Race"
];
export const MOCK_ETHNICITY: string[] = [
  "Hispanic or Latino",
  "Not Hispanic or Latino"
];
export const MOCK_LANGUAGES: string[] = [
  "English",
  "Spanish",
  "French",
  "Russian",
  "Portuguese",
  "Chinese",
  "Other"
];

export const MOCK_QUESTIONS: string[] = [
  "Has your child experienced any fever recently?",
  "Has your child experienced any runny nose?",
  "Has your child recently experienced any sore throat?",
  "Has your child recently experienced any cough?",
  "Has your child shown any signs of being out of breath or wheezing?",
  "Has your child experienced any vomiting?",
  "Has your child had any illnesses recently?"
];

export const MOCK_AGE_GROUPS: ChildAgeGroup[] = [
  { id: 0, tag: "NEWBORN", yearStart: 0, value: 0 },
  { id: 1, tag: "A", yearStart: 1 / 24, value: 1 },
  { id: 2, tag: "B", yearStart: 1 / 12, value: 2 },
  { id: 3, tag: "C", yearStart: 1 / 6, value: 3 },
  { id: 4, tag: "E", yearStart: 1 / 3, value: 4 },
  { id: 5, tag: "F", yearStart: 0.5, value: 5 },
  { id: 6, tag: "G", yearStart: 0.75, value: 6 },
  { id: 7, tag: "H", yearStart: 1, value: 7 },
  { id: 8, tag: "I", yearStart: 1.25, value: 8 },
  { id: 9, tag: "J", yearStart: 1.5, value: 9 },
  { id: 10, tag: "K", yearStart: 2, value: 10 },
  { id: 11, tag: "L", yearStart: 2.5, value: 11 },
  { id: 12, tag: "M", yearStart: 3, value: 12 },
  { id: 13, tag: "N", yearStart: 4, value: 13 },
  { id: 14, tag: "O", yearStart: 5, value: 14 },
  { id: 15, tag: "P", yearStart: 12, value: 15 },
  { id: 16, tag: "ADULT", yearStart: 18, value: 16 }
];

export const MOCK_STATES = [
  "ALABAMA",
  "AL",
  "ALASKA",
  "AK",
  "AMERICANSAMOA",
  "AS",
  "ARIZONA",
  "AZ",
  "ARKANSAS",
  "AR",
  "CALIFORNIA",
  "CA",
  "COLORADO",
  "CO",
  "CONNECTICUT",
  "CT",
  "DELAWARE",
  "DE",
  "DISTRICTOFCOLUMBIA",
  "DC",
  "FEDERATEDSTATESOFMICRONESIA",
  "FM",
  "FLORIDA",
  "FL",
  "GEORGIA",
  "GA",
  "GUAM",
  "GU",
  "HAWAII",
  "HI",
  "IDAHO",
  "ID",
  "ILLINOIS",
  "IL",
  "INDIANA",
  "IN",
  "IOWA",
  "IA",
  "KANSAS",
  "KS",
  "KENTUCKY",
  "KY",
  "LOUISIANA",
  "LA",
  "MAINE",
  "ME",
  "MARSHALLISLANDS",
  "MH",
  "MARYLAND",
  "MD",
  "MASSACHUSETTS",
  "MA",
  "MICHIGAN",
  "MI",
  "MINNESOTA",
  "MN",
  "MISSISSIPPI",
  "MS",
  "MISSOURI",
  "MO",
  "MONTANA",
  "MT",
  "NEBRASKA",
  "NE",
  "NEVADA",
  "NV",
  "NEWHAMPSHIRE",
  "NH",
  "NEWJERSEY",
  "NJ",
  "NEWMEXICO",
  "NM",
  "NEWYORK",
  "NY",
  "NORTHCAROLINA",
  "NC",
  "NORTHDAKOTA",
  "ND",
  "NORTHERNMARIANAISLANDS",
  "MP",
  "OHIO",
  "OH",
  "OKLAHOMA",
  "OK",
  "OREGON",
  "OR",
  "PALAU",
  "PW",
  "PENNSYLVANIA",
  "PA",
  "RHODEISLAND",
  "RI",
  "SOUTHCAROLINA",
  "SC",
  "SOUTHDAKOTA",
  "SD",
  "TENNESSEE",
  "TN",
  "TEXAS",
  "TX",
  "UTAH",
  "UT",
  "VERMONT",
  "VT",
  "VIRGINISLANDS",
  "VI",
  "VIRGINIA",
  "VA",
  "WASHINGTON",
  "WA",
  "WESTVIRGINIA",
  "WV",
  "WISCONSIN",
  "WI",
  "WYOMING",
  "WY"
];

export const MOCK_AREA_CODES = [
  "201",
  "202",
  "203",
  "204",
  "205",
  "206",
  "207",
  "208",
  "209",
  "210",
  "212",
  "213",
  "214",
  "215",
  "216",
  "217",
  "218",
  "219",
  "220",
  "223",
  "224",
  "225",
  "226",
  "228",
  "229",
  "231",
  "234",
  "236",
  "239",
  "240",
  "248",
  "249",
  "250",
  "251",
  "252",
  "253",
  "254",
  "256",
  "260",
  "262",
  "267",
  "269",
  "270",
  "272",
  "276",
  "279",
  "281",
  "289",
  "301",
  "302",
  "303",
  "304",
  "305",
  "306",
  "307",
  "308",
  "309",
  "310",
  "312",
  "313",
  "314",
  "315",
  "316",
  "317",
  "318",
  "319",
  "320",
  "321",
  "323",
  "325",
  "330",
  "331",
  "332",
  "334",
  "336",
  "337",
  "339",
  "340",
  "343",
  "347",
  "351",
  "352",
  "360",
  "361",
  "364",
  "365",
  "367",
  "380",
  "385",
  "386",
  "401",
  "402",
  "403",
  "404",
  "405",
  "406",
  "407",
  "408",
  "409",
  "410",
  "412",
  "413",
  "414",
  "415",
  "416",
  "417",
  "418",
  "419",
  "423",
  "424",
  "425",
  "430",
  "431",
  "432",
  "434",
  "435",
  "437",
  "438",
  "440",
  "442",
  "443",
  "445",
  "450",
  "458",
  "463",
  "469",
  "470",
  "475",
  "478",
  "479",
  "480",
  "484",
  "501",
  "502",
  "503",
  "504",
  "505",
  "506",
  "507",
  "508",
  "509",
  "510",
  "512",
  "513",
  "514",
  "515",
  "516",
  "517",
  "518",
  "519",
  "520",
  "530",
  "531",
  "534",
  "539",
  "540",
  "541",
  "548",
  "551",
  "559",
  "561",
  "562",
  "563",
  "564",
  "567",
  "570",
  "571",
  "573",
  "574",
  "575",
  "579",
  "580",
  "581",
  "585",
  "586",
  "587",
  "601",
  "602",
  "603",
  "604",
  "605",
  "606",
  "607",
  "608",
  "609",
  "610",
  "612",
  "613",
  "614",
  "615",
  "616",
  "617",
  "618",
  "619",
  "620",
  "623",
  "626",
  "628",
  "629",
  "630",
  "631",
  "636",
  "640",
  "641",
  "646",
  "647",
  "650",
  "651",
  "657",
  "660",
  "661",
  "662",
  "667",
  "669",
  "670",
  "671",
  "678",
  "680",
  "681",
  "682",
  "684",
  "701",
  "702",
  "703",
  "704",
  "705",
  "706",
  "707",
  "708",
  "709",
  "712",
  "713",
  "714",
  "715",
  "716",
  "717",
  "718",
  "719",
  "720",
  "724",
  "725",
  "726",
  "727",
  "731",
  "732",
  "734",
  "740",
  "743",
  "747",
  "754",
  "757",
  "760",
  "762",
  "763",
  "765",
  "769",
  "770",
  "772",
  "773",
  "774",
  "775",
  "778",
  "779",
  "780",
  "781",
  "782",
  "785",
  "786",
  "787",
  "801",
  "802",
  "803",
  "804",
  "805",
  "806",
  "807",
  "808",
  "810",
  "812",
  "813",
  "814",
  "815",
  "816",
  "817",
  "818",
  "819",
  "820",
  "825",
  "828",
  "830",
  "831",
  "832",
  "838",
  "843",
  "845",
  "847",
  "848",
  "850",
  "854",
  "856",
  "857",
  "858",
  "859",
  "860",
  "862",
  "863",
  "864",
  "865",
  "867",
  "870",
  "872",
  "873",
  "878",
  "901",
  "902",
  "903",
  "904",
  "905",
  "906",
  "907",
  "908",
  "909",
  "910",
  "912",
  "913",
  "914",
  "915",
  "916",
  "917",
  "918",
  "919",
  "920",
  "925",
  "928",
  "929",
  "930",
  "931",
  "934",
  "936",
  "937",
  "938",
  "939",
  "940",
  "941",
  "947",
  "949",
  "951",
  "952",
  "954",
  "956",
  "959",
  "970",
  "971",
  "972",
  "973",
  "978",
  "979",
  "980",
  "984",
  "985",
  "986",
  "989",
  // codes not in official list of area codes (https://www.allareacodes.com/)
  "242",
  "246",
  "264",
  "268",
  "284",
  "345",
  "441",
  "456",
  "473",
  "500",
  "533",
  "600",
  "649",
  "664",
  "700",
  "710",
  "758",
  "767",
  "784",
  "800",
  "809",
  "829",
  "849",
  "855",
  "866",
  "868",
  "869",
  "876",
  "877",
  "888",
  "900"
];
