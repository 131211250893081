import _ from "@/lodash";

import { arrayOf } from "@/helpers/general";
import { Mapping, Option, Paths } from "@/types";

type StringAble =
  | string
  | {
      toString: () => string;
      [prop: string]: any;
    };

export const transformToOptions = <
  T extends Record<string, StringAble>,
  K extends keyof T | undefined
>(
  arr: T[],
  keyText: keyof T,
  keyValue: K
): Option<K extends keyof T ? T[K] : T>[] =>
  arr.map<Option>((i) => ({
    text: i[keyText].toString(),
    value: keyValue ? i[keyValue as any] : i
  }));

export const getNumberOptions = (range: [number, number]) =>
  arrayOf(range[1] - range[0] + 1).map((_, i) => ({
    value: i + range[0],
    text: (i + range[0]).toString()
  }));

export const getPaths = (tree: any) => {
  const leaves: string[] = [];
  const _walk = (obj: any, path = "", isArray = false) => {
    for (const n in obj) {
      if (obj.hasOwnProperty(n)) {
        if (_.isPlainObject(obj[n]))
          _walk(obj[n], path + "." + n, obj[n] instanceof Array);
        else
          leaves.push(
            (isArray ? `${path}[${n}]` : `${path}.${n}`).substring(1)
          );
      }
    }
  };
  _walk(tree);
  return leaves;
};

export const updatePartialObject = <T extends object>(
  original: T,
  update: Partial<T>,
  transformFn: (val: any) => any = (x) => x
) => {
  const allowedPaths = getPaths(original);
  const paths = getPaths(update);
  paths.forEach((p) => {
    if (allowedPaths.includes(p))
      _.set(original, p, transformFn(_.get(update, p)));
  });
  return original;
};

export const fromString = (str: string) => {
  const num = Number(str);
  if (!isNaN(num)) return num;
  if (str === "true") return true;
  if (str === "null") return null;
  if (str === "false") return false;
  return str;
};

export const removeEmpty = <T extends Record<string, any>>(obj: T): any =>
  Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  );

export const convertUsingMappig = <
  TA extends Record<string, any> = any,
  TB extends Record<string, any> = any
>(
  object: TA,
  mapping: Mapping<TA, TB>,
  transform: (el: any, elPath: Paths<TA>, newPath: Paths<TB>) => any = (el) =>
    el
): Partial<TB> => {
  const partialObj = {};
  _.forIn(mapping, (pathB, pathA) => {
    const val = _.get(object, pathA);
    if (val !== undefined && val !== null)
      _.set(
        partialObj,
        pathB as string,
        transform(val, pathA as any, pathB as any)
      );
  });
  return partialObj as Partial<TB>;
};
