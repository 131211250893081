import { useRouter } from "next/router";
import { useEffect } from "react";
import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import type { Moment } from "moment-timezone";
import _ from "@/lodash";

import { searchSlice } from "./slice";
import { searchStageCheckers } from "./stages";
import {
  fetchConstants,
  fetchOptions1,
  fetchOptions2,
  fetchSearchResults
} from "../thunks";
import {
  appointmentToSearch,
  getSearchStateFromParams,
  loadSearchFromParams
} from "../actions";

import { SearchMiddleware, useData } from "@/store";
import {
  dateNullToString,
  extractRouteAndParams,
  monthsBetween,
  paramsStringToObject,
  stringToDate
} from "@/helpers";
import { Age, AppStateDef, ChildAgeGroup, Patient, SearchStage } from "@/types";

export const searchMiddleware = createListenerMiddleware<AppStateDef>();

// APPOINTMENT TO SEARCH
searchMiddleware.startListening({
  actionCreator: appointmentToSearch,
  effect: async (_, listenerApi) => {
    const state = listenerApi.getOriginalState();

    listenerApi.dispatch(loadSearchFromParams(state.appointment.searchParams));
    await listenerApi.dispatch(fetchSearchResults());
  }
});

// LOAD SEARCH FROM PARAMS
searchMiddleware.startListening({
  actionCreator: loadSearchFromParams,
  effect: (action, listenerApi) => {
    const state = listenerApi.getOriginalState();

    const paramsString =
      action.payload.paramsString ?? state.appointment.searchParams;

    const partialObj = getSearchStateFromParams(
      paramsStringToObject(paramsString),
      action.payload.paramsToSearchMap
    );

    listenerApi.dispatch(searchSlice.actions.setPartialState(partialObj));
    listenerApi.dispatch(searchSlice.actions.switchStage(null));
  }
});

// FETCH CONSTS & OPTIONS ON STATE CHANGE
searchMiddleware.startListening({
  matcher: isAnyOf(
    searchSlice.actions.setState,
    searchSlice.actions.setPartialState
  ),
  effect: (action, listenerApi) => {
    listenerApi.dispatch(fetchOptions1());
    listenerApi.dispatch(fetchOptions2());
    listenerApi.dispatch(
      fetchConstants(
        action.payload?.patient ?? listenerApi.getOriginalState().search.patient
      )
    );
  }
});

// AUTO-SWITCH STAGE
searchMiddleware.startListening({
  predicate: () => true,
  effect: (action, listenerApi) => {
    const newSearchState = listenerApi.getState().search;

    if (
      searchStageCheckers[SearchStage.SEARCH2](newSearchState) &&
      newSearchState.stage === SearchStage.SEARCH1
    ) {
      listenerApi.dispatch(
        searchSlice.actions.switchStage(SearchStage.SEARCH2)
      );
      listenerApi.dispatch(fetchOptions2());
    }
    if (
      searchStageCheckers[SearchStage.SEARCH1](newSearchState) &&
      newSearchState.stage > SearchStage.SEARCH1
    )
      listenerApi.dispatch(
        searchSlice.actions.switchStage(SearchStage.SEARCH1)
      );
  }
});

// ON SET
searchMiddleware.startListening<typeof searchSlice.actions.set>({
  actionCreator: searchSlice.actions.set,
  effect: (action, listenerApi) => {
    const searchState = listenerApi.getOriginalState().search;
    const key1 = action.payload.key.split(".")[0];

    // On Set
    if (key1 === "search1" || key1 === "age" || key1 === "birthday") {
      if (searchState.didInitParams) {
        listenerApi.dispatch(fetchOptions1());
        listenerApi.dispatch(fetchOptions2());
      }
    }
    if (key1 === "search2") {
      if (searchState.didInitParams) listenerApi.dispatch(fetchOptions2());
    }
    if (key1 === "patient") {
      listenerApi.dispatch(fetchConstants(action.payload.value));
      if (searchState.didInitParams)
        listenerApi.dispatch(
          searchSlice.actions.setPartialState!(
            _.omit(searchSlice.getInitialState(), [
              "patient",
              "didInitParams",
              "search2.date"
            ])
          )
        );
    }
  }
});

// ON SWITCH STAGE
searchMiddleware.startListening<typeof searchSlice.actions.switchStage>({
  actionCreator: searchSlice.actions.switchStage,
  effect: (action, listenerApi) => {
    const searchState = listenerApi.getOriginalState().search;
    const newSearchState = listenerApi.getState().search;

    if (searchState.stage !== newSearchState.stage) {
      if (newSearchState.stage === SearchStage.SEARCH1)
        listenerApi.dispatch(
          searchSlice.actions.set({
            key: "search2",
            value: searchSlice.getInitialState().search2
          })
        );
    }
  }
});

// WRITE SEARCH PARAMS
export const useSearchParams: SearchMiddleware = (search) => {
  const router = useRouter();
  const {
    age,
    patient,
    birthday,
    didInitParams,
    search2,
    selectedResult,
    selectedTime
  } = search.state;
  const { type, gender, globalCategory, specialty, reason } =
    search.state.search1;

  const insurance = search2.insurance;
  const doctor = selectedResult?.doctor.id ?? search2.doctor;
  const doctorName = selectedResult?.doctor.name;
  const location = selectedResult?.doctor.location ?? search2.location;
  const date = selectedResult?.date ?? search2.date;

  const time = selectedTime?.startTime;
  const timeEnd = selectedTime?.endTime;
  const idTimeSlot = selectedTime?.idTimeSlot;

  useEffect(() => {
    if (!didInitParams) return;

    const { route } = extractRouteAndParams(router.asPath);
    if (route !== "/search") return;

    // update Query
    router
      .replace(
        {
          query: _.omitBy(
            {
              age,
              patient,
              birthday,
              type,
              gender,
              reason,
              globalCategory,
              doctor,
              doctorName,
              specialty,
              location,
              insurance,
              date,
              time,
              timeEnd,
              idTimeSlot
            },
            _.isNil
          )
        },
        undefined,
        {
          shallow: true
        }
      )
      .then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    didInitParams,
    router.asPath,
    patient,
    age,
    birthday,
    gender,
    type,
    specialty,
    reason,
    globalCategory,
    doctor,
    doctorName,
    location,
    insurance,
    date,
    time,
    timeEnd,
    idTimeSlot
  ]);
};

// CALC CHILD AGE-GROUP
export const useAgeCalculation: SearchMiddleware = (search) => {
  const { bounds, childAgeGroups } = useData();
  const ageTolerance = bounds.ageTolerance;

  const isChild = search.valueOf<Patient>("patient") === Patient.PEDIATRIC;
  const birthday = dateNullToString(search.valueOf<Moment>("birthday"));
  const date = dateNullToString(search.valueOf<Moment>("search2.date"));

  const valueSet = search.valueSet;
  useEffect(() => {
    if (birthday) valueSet("age", null);
    if (!isChild) valueSet("birthday", null);
  }, [isChild, valueSet, birthday]);

  useEffect(() => {
    if (birthday !== null && date !== null && isChild) {
      valueSet(
        "age",
        calculateChildAgeValue(
          stringToDate(birthday),
          stringToDate(date),
          [...childAgeGroups],
          ageTolerance
        )
      );
    }
  }, [birthday, date, isChild, valueSet, ageTolerance, childAgeGroups]);
};

const calculateChildAgeValue = (
  birthday: Moment,
  appointment: Moment,
  ageGroups: ChildAgeGroup[],
  tolerance = 0
): Age => {
  const sortedAgeGroups = ageGroups.sort((a, b) => b.yearStart - a.yearStart);

  const months = monthsBetween(birthday, appointment);
  const age = months / 12 + tolerance;

  console.log("Calculated age is: ", age);

  for (const a of sortedAgeGroups) {
    if (age > a.yearStart) return a.value;
  }
  return sortedAgeGroups[sortedAgeGroups.length - 1].value;
};
