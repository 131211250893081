import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Modal } from "react-bootstrap";

import { useMounted } from "@/helpers";

import classes from "./Popup.module.scss";

const IDENTIFIER = "myGlobalPortal123";

const Popup = (props: {
  open?: boolean;
  disabled?: boolean;
  backdropClose?: boolean;
  onClose?: () => void;
  className?: string;
  contentClassName?: string;
  children: ReactNode;
}): JSX.Element => {
  const [open, setOpen] = useState(props.open !== false);

  useEffect(() => {
    setOpen(props.open !== false);
  }, [props.open]);

  const mounted = useMounted();

  return mounted ? (
    createPortal(
      <PopupComponent
        className={props.className}
        contentClassName={props.contentClassName}
        open={(!props.disabled && props.open) || open}
        backdropClose={props.backdropClose}
        onHide={() => {
          setOpen(false);
          if (props.onClose) props.onClose();
        }}
      >
        {props.children}
      </PopupComponent>,
      document.querySelector("#" + IDENTIFIER)!
    )
  ) : (
    <></>
  );
};

export default Popup;

const PopupComponent: (props: {
  children: ReactNode;
  onHide: () => void;
  open?: boolean;
  className?: string;
  contentClassName?: string;
  backdropClose?: boolean;
}) => JSX.Element = ({
  children,
  onHide,
  open,
  backdropClose,
  className,
  contentClassName
}) => {
  return (
    <Modal
      show={open}
      onHide={onHide}
      backdrop={!backdropClose ? "static" : true}
      className={classes.popup + " " + className}
    >
      <div className={classes.content + " " + contentClassName}>{children}</div>
    </Modal>
  );
};

export const PopupPlace = () => <div id={IDENTIFIER} />;
