import { Col, Row } from "react-bootstrap";

import { AppointmentInfoProps, Patient } from "@/types";

import classes from "./AppointmentInfoList.module.scss";
import { displayTimeUSA } from "@/helpers";

const AppointmentInfoList = (
  props: AppointmentInfoProps & {
    horizontal?: boolean;
    className?: string;
  }
): JSX.Element => {
  return (
    <Row>
      <Col lg={props.horizontal ? 8 : 12} md={12}>
        <ul
          className={
            classes.list +
            " " +
            (props.horizontal ? classes.horizontal : "") +
            " " +
            props.className
          }
        >
          <li>
            <i className="fas fa-user" /> {props.doctorName}
          </li>
          <li>
            <i className="fas fa-calendar-alt" /> {props.dateString}
          </li>
          <li>
            <i className="fas fa-clock" /> {displayTimeUSA(props.time)}{" "}
            {props.timeEnd && ` - ${displayTimeUSA(props.timeEnd)}`}
          </li>
          <li>
            <i className="fas fa-map-marker-alt" /> {props.location}
          </li>
        </ul>
      </Col>
      {!!props.details && (
        <Col sm={12}>
          <hr />
          {!props.horizontal && <h4>Type of visit:</h4>}
          <ul
            className={
              classes.list +
              " " +
              (props.horizontal ? classes.horizontal : "") +
              " " +
              classes.visit
            }
          >
            {props.details?.map((d, i) => (
              <li key={i}>
                <i className="fas fa-circle" />
                {d}
              </li>
            ))}
          </ul>
        </Col>
      )}
    </Row>
  );
};

export default AppointmentInfoList;

export const transformToAppointmentDetails = (data: {
  patient: Patient;
  visitType?: string;
  globalCategory?: string;
  visitReason?: string;
}) => [
  data.patient === Patient.PEDIATRIC ? "Child" : "Adult",
  ...(data.visitType ? [data.visitType + " visit"] : []),
  ...(data.globalCategory ? [data.globalCategory + " visit"] : []),
  ...(data.visitReason ? [data.visitReason] : [])
];
