import {
  Practice,
  Gender,
  VisitType,
  GlobalCategory,
  Patient,
  DateISOString
} from "./global";
import {
  IDType,
  SearchResult,
  Age,
  Doctor,
  Specialty,
  Insurance,
  ChildAgeGroup,
  Location,
  TimeSlot,
  SingleCost
} from "./database";
import { Option } from "./input";
import {
  BE_ConsentsAnswered,
  BE_ConsentsResponse,
  BE_IDENT,
  BE_InsuranceVerifyResponse,
  BE_MissingData,
  BE_PreRegQuestionnaire,
  BE_PreScreeningQuestionnaireResponse
} from "./api";

export type PartialDataState<
  K extends keyof DataState,
  B extends boolean = false
> = B extends true ? { [T in K]: DataState[T] } : { [T in K]?: DataState[T] };

export type DataState = {
  static: {
    contact: {
      // states_cities: Record<string, string[]>;
      races: Option<string>[];
      ethnicities: Option<string>[];
      languages: Option<string>[];
    };
    session: {
      timeout: number;
    };
    bounds: {
      adultMinAge: number;
      adultMaxAge: number;
      parentMinAge: number;
      parentMaxAge: number;
      ageTolerance: number; // in years
    };
  };
  options1: {
    patients: Option<Patient>[];
    genders: Option<Gender>[];
    visitTypes: Option<VisitType>[];
    globalCategories: Option<GlobalCategory>[];
    specialties: Option<IDType>[];
    reasons: Option<IDType>[];
  };
  options2: {
    doctors: Option<IDType>[];
    insurances: Option<IDType>[];
    locations: Option<IDType>[];
  };
  constants: {
    doctors: Doctor[];
    insurances: Insurance[];
    specialties: Specialty[];
    locations: Location[];
    childAgeGroups: ChildAgeGroup[];
  };
};

export enum SearchStage {
  SEARCH1,
  SEARCH2,
  RESULTS
}

export type SearchState = {
  stage: SearchStage;

  patient: Patient;
  birthday: DateISOString | null;
  search1: {
    gender: Gender | null;
    type: VisitType | null;
    globalCategory: GlobalCategory | null;
    specialty: IDType | null;
    reason: IDType | null;
  };
  search2: {
    doctor: IDType | null;
    insurance: IDType | null;
    location: IDType | null;
    date: DateISOString;
  };
  age: Age | null;
  isFetching1: boolean;
  isFetching2: boolean;
  isSearching: boolean;
  didInitParams: boolean;

  sort: {};
  results: SearchResult[];
  selectedResult: SearchResult | null;
  selectedTime: TimeSlot | null;
  previousDate: DateISOString;
};

export enum AppointmentStage { // Ordered!
  SEARCH,
  INFO,
  PARENT,
  CONTACT,
  INSURANCE,
  PRE_SCREENING,
  COSTS1,
  QUESTIONS,
  COSTS2,
  SIGN,
  PAYMENT,
  DONE
}

export type AppointmentState = {
  idPatient: IDType | null;
  token: string | null;

  stage: AppointmentStage;
  searchParams: string;

  /* - DYNAMIC IN APP - */
  patient: Patient;
  visit: {
    type: VisitType | null;
    globalCategory: GlobalCategory | null;
    reason: IDType | null;
    location: IDType | null;
  };
  doctor: IDType | null;
  doctorName: string;
  specialty: IDType | null;
  idTimeSlot: IDType | null;
  date: DateISOString | null;
  time: string;
  timeEnd: string;

  practice: Practice | null;

  info: {
    age: Age | null;
    gender: Gender | null;

    firstName: string;
    lastName: string;
    birthday: DateISOString | null;
  };

  /* - STORED ON SERVER (established) - */
  parent_info: {
    firstName: string;
    lastName: string;
    birthday: DateISOString | null;
  };

  contact: {
    phone: string;
    email: string;
    address: {
      street: string;
      street2: string;
      city: string;
      state: string;
      zip: string;
    };
    race: string;
    ethnicity: string;
    language: string;
  };
  contactMissingData: BE_MissingData;

  insurance: {
    id: IDType | null;
    name: string;
    number: string;
    holder: {
      firstName: string;
      lastName: string;
      birthday: DateISOString | null;
      gender: Gender | null;
    } | null;
  } | null;
  insuranceCostsData: BE_InsuranceVerifyResponse | null;

  preScreeningData: BE_PreScreeningQuestionnaireResponse | null;

  preRegQuestionnaireList: { id: number; name: string }[];
  preRegQuestionnaires: Record<
    number,
    {
      name: string;
      questionnaire: BE_PreRegQuestionnaire | null;
      answers: Record<BE_IDENT, any>;
      finished: boolean;
      activeIndex: number;
    }
  >;
  preRegQuestionnaireActive: number | null;

  costs1: SingleCost[];

  consentsData: BE_ConsentsResponse | null;
  consents: BE_ConsentsAnswered;

  sign: {
    firstName: string;
    lastName: string;
  };
  signature: any;
  payment: {
    clientSecret: string;
  };

  errorMessage: string;
  errorCode: number | null;
  errorAction: string;
  loading: boolean;
};

export enum DialogResponse {
  CANCEL,
  CONFIRM,
  BUTTON2
}

export type Dialog = {
  message: string;
  open?: boolean;
  type?: "WARNING" | "INFO";
  heading?: string;
  icon?: string;
  buttonText?: string;
  buttonText2?: string;
};
export type DialogState = {
  dialogs: Record<string, Dialog>;
  showId: string | null;
};

export type AppStateDef = {
  data: DataState;
  search: SearchState;
  appointment: AppointmentState;
  dialog: DialogState;
};
