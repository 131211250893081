import { DataState } from "@/types";

const initialState: DataState = {
  static: {
    contact: {
      ethnicities: [],
      languages: [],
      races: []
    },
    session: {
      timeout: 30 * 60 * 1000
    },
    bounds: {
      adultMaxAge: 120,
      adultMinAge: 16,
      ageTolerance: (1 / 12 / 30) * 2,
      parentMaxAge: 120,
      parentMinAge: 18
    }
  },
  options1: {
    patients: [],
    genders: [],
    globalCategories: [],
    visitTypes: [],
    specialties: [],
    reasons: []
  },
  options2: {
    doctors: [],
    insurances: [],
    locations: []
  },
  constants: {
    childAgeGroups: [],
    specialties: [],
    locations: [],
    doctors: [],
    insurances: []
  }
};
export default initialState;
