import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import type { UrlObject } from "url";
import { captureException } from "@sentry/nextjs";

import { timeoutPromise } from "./general";

import { API } from "@/constants/api";
import { LOCAL_KEYS } from "@/constants";
import { useAppointment, useData, useSearch } from "@/store";
import { getSearchStateFromParams } from "@/store/actions";
import {
  AppointmentState,
  DataProps,
  DataState,
  LoadState,
  PartialDataState,
  Patient,
  SearchStage,
  SearchState
} from "@/types";
import { getClientSecretFromQuery } from "@/helpers/stripe";

export const handleError = <T>(err: any, fb?: T) => {
  if (fb !== undefined) {
    console.warn(`[${new Date().toISOString()}]`, "Error: ", err?.message);
    captureException(err);
    return fb;
  } else throw err;
};

export const extractRouteAndParams = (routerAsPath: string) => {
  const QI =
    (routerAsPath.length + routerAsPath.indexOf("?") + 1) %
    (routerAsPath.length + 1);
  const route = routerAsPath.substring(0, QI);
  const paramsStr = routerAsPath.substring(QI);
  const paramsObj = paramsStringToObject(paramsStr);

  return { route, paramsStr, paramsObj };
};

export const paramsStringToObject = (searchParams: string) =>
  Object.fromEntries(new URLSearchParams(searchParams));

export const fetchSSR = async <T extends any>(
  fn: () => Promise<T> | T,
  fb: T
): Promise<T> => {
  try {
    return await timeoutPromise(fn, 5000);
  } catch (e: any) {
    console.warn("Error in SSR!");
    console.log(e);
    return handleError(e, fb);
  }
};

export const fetchAllData = async (
  searchState: Partial<SearchState>,
  isSSR?: boolean
): Promise<Partial<DataState>> => ({
  ...(await fetchOptions(searchState, isSSR)),
  ...(searchState.patient
    ? await fetchConstants(searchState.patient, isSSR)
    : {}),
  ...(await fetchStatics(isSSR))
});

export const fetchOptions = async (
  searchState: Partial<SearchState>,
  isSSR?: boolean
) => {
  const res: PartialDataState<"options1"> & PartialDataState<"options2"> = {};
  const age = searchState.age;
  const dob = searchState.birthday;
  const patient = searchState.patient ?? Patient.PEDIATRIC;
  const { type, gender, globalCategory, specialty, reason } =
    searchState.search1 ?? {};
  const { doctor, location, insurance, date } = searchState.search2 ?? {
    date: null
  };

  if (searchState.search1 || patient) {
    res.options1 = await API(isSSR).search.fetchOptions1({
      patient,
      gender,
      age,
      globalCategory: globalCategory,
      type: type,
      specialty: specialty,
      reason: reason,
      dob,
      date
    });
    if (searchState.search2)
      res.options2 = await API(isSSR).search.fetchOptions2({
        patient,
        gender: gender!,
        age: age!,
        globalCategory: globalCategory!,
        type: type!,
        doctor,
        specialty: specialty!,
        reason: reason!,
        insurance,
        location: location,
        dob: dob!,
        date
      });
  }

  return res;
};
export const fetchConstants = async (
  patient?: Patient,
  isSSR?: boolean
): Promise<PartialDataState<"constants", true>> => ({
  constants: await API(isSSR).fetchConstants({ patient })
});
export const fetchStatics = async (
  isSSR?: boolean
): Promise<PartialDataState<"static", true>> => ({
  static: await API(isSSR).fetchStatics()
});

export const fetchLocalAppointment = () => {
  const appointment = sessionStorage.getItem(LOCAL_KEYS.APPOINTMENT);
  if (!appointment) return;

  return JSON.parse(appointment) as AppointmentState;
};

export const getSearchStateFromUrl = (url: string) => {
  const { route, paramsObj } = extractRouteAndParams(url);
  return route === "/search" ? getSearchStateFromParams(paramsObj) : {};
};

export const useInitDataProps = (
  props: DataProps & any,
  additionalData?: () => any
) => {
  const { setStatics, updateState } = useData();
  const [loader, setLoader] = useState(LoadState.DEFAULT);

  useEffect(() => {
    const loadStaticsAndConstants = async () => {
      updateState(props);

      // Fallback
      if (!props.static) setStatics(await API().fetchStatics());

      // Custom
      if (additionalData) await additionalData();
    };
    if (loader === LoadState.DEFAULT) {
      setLoader(LoadState.LOADING);
      loadStaticsAndConstants().then(() => setLoader(LoadState.LOADED));
    }
  }, [updateState, props, setStatics, loader, additionalData]);

  return loader !== LoadState.LOADED;
};

export const useInitSearchPage = (
  dataProps: DataProps,
  initSearchState: Partial<SearchState>
) => {
  const {
    updateState,
    selectAppointment,
    confirmAppointment,
    forceSwitchStage,
    search,
    state: { didInitParams }
  } = useSearch();

  /* Init Fn */
  const _init = useCallback(async () => {
    if (didInitParams) return;

    updateState({ ...initSearchState, didInitParams: true });
    if (initSearchState.selectedTime && initSearchState.selectedResult) {
      search();
      forceSwitchStage(SearchStage.RESULTS);
      selectAppointment(
        initSearchState.selectedResult,
        initSearchState.selectedTime
      );
    }

    const isConfirmed =
      new URLSearchParams(window.location.search).get("_confirm") === "1";
    if (isConfirmed) setTimeout(() => confirmAppointment(), 0);
  }, [
    initSearchState,
    updateState,
    selectAppointment,
    confirmAppointment,
    didInitParams,
    search,
    forceSwitchStage
  ]);

  return useInitDataProps(dataProps, _init);
};

export const useInitAppointmentPage = (
  dataProps: DataProps,
  initAppointmentState: AppointmentState | null
) => {
  const { setState, updateState } = useAppointment();
  const router = useRouter();

  /* Init Local Appointment */
  useEffect(() => {
    const localAppointment = fetchLocalAppointment();
    if (localAppointment) setState(localAppointment);
    if (initAppointmentState) setState(initAppointmentState);
  }, [setState, initAppointmentState]);

  /* Init Payment Secret */
  const urlClientSecret = getClientSecretFromQuery(router.asPath);
  const _initPaymentSecret = useCallback(async () => {
    if (!urlClientSecret) return;
    updateState({
      payment: {
        clientSecret: urlClientSecret
      }
    });
  }, [updateState, urlClientSecret]);

  return useInitDataProps(dataProps, _initPaymentSecret);
};

export const useCaptureRouterError = () => {
  const router = useRouter();

  useEffect(() => {
    if (router.query.err)
      captureException(new Error(router.query.err.toString()));
  }, [router.query.err]);
};

export const useOnRouteChange = (
  cb: (newUrl: string) => any,
  onEvent: "start" | "complete" = "complete"
) => {
  const router = useRouter();

  useEffect(() => {
    const onPageLeave = (url: string) => {
      const urlPathName = url.substring(
        0,
        url.indexOf("?") > -1 ? url.indexOf("?") : url.length
      );
      if (urlPathName === router.pathname) return;

      cb(urlPathName);
    };
    const event =
      onEvent === "complete" ? "routeChangeComplete" : "routeChangeStart";
    router.events.on(event, onPageLeave);
    return () => {
      router.events.off(event, onPageLeave);
    };
  }, [cb, router, onEvent]);

  return router;
};

export const useRouteGuard = (
  canPass = false,
  redirect: UrlObject | string = "/",
  shallow = false
) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (canPass || loading) return;

    setLoading(true);
    router
      .push(redirect, undefined, { shallow })
      .then(() => console.log(`Redirected to ${redirect}`))
      .catch(() => setLoading(false));
  }, [canPass, router, redirect, loading, shallow]);

  return loading;
};
