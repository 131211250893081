import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import initialState from "./init";
import { getValueReducer } from "@/store/util";
import { fetchOptions1, fetchOptions2, fetchConstants } from "../thunks";

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    ...getValueReducer<typeof initialState>(),
    setConstants: (state, action: PayloadAction<typeof state.constants>) => {
      state.constants = action.payload;
    },
    setOptions1: (state, action: PayloadAction<typeof state.options1>) => {
      state.options1 = action.payload;
    },
    setOptions2: (state, action: PayloadAction<typeof state.options2>) => {
      state.options2 = action.payload;
    },
    setStatics: (state, action: PayloadAction<typeof state.static>) => {
      state.static = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOptions1.fulfilled, (state, action) => {
      state.options1 = action.payload;
    });
    builder.addCase(fetchOptions2.fulfilled, (state, action) => {
      state.options2 = action.payload;
    });
    builder.addCase(fetchConstants.fulfilled, (state, action) => {
      state.constants = action.payload;
    });
  }
});
