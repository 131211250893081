import { ReactNode, useEffect, useState } from "react";
import { useRouter } from "next/router";

import Dialog from "@/components/UI/Dialog";

import { useSearchMiddleware } from "./search/helpers";
import {
  useAppointment,
  useAppointmentMiddleware
} from "./appointment/helpers";
import { useDialog, useDialogMiddleware } from "./dialog/helpers";
import { AppointmentStage, DialogResponse } from "@/types";

// WIP
export const useRouteChange = () => {
  const app = useAppointment();
  const { showDialog } = useDialog();
  const router = useRouter();

  const sessionStarted =
    app.stage > AppointmentStage.SEARCH && app.stage < AppointmentStage.PAYMENT;

  const forceSwitchStage = app.forceSwitchStage;

  /* On router change route  */
  const [allowIt, setAllowIt] = useState(true); // WIP
  useEffect(() => {
    const onRouteChange = (url: string) => {
      if (url === "/search") forceSwitchStage(AppointmentStage.SEARCH); // WIP

      if (!sessionStarted || allowIt) return;

      router.events.emit("routeChangeError");
      showDialog("Are you sure?").then((x) => {
        if (x !== DialogResponse.CONFIRM) return;
        setTimeout(() => router.push(url).then(() => setAllowIt(false)), 0); // * a Hack
        setAllowIt(true);
      });
      throw "Intended Error. Ignore Safely.";
    };

    router.events.on("routeChangeStart", onRouteChange);
    return () => {
      router.events.off("routeChangeStart", onRouteChange);
    };
  }, [sessionStarted, router, showDialog, allowIt, forceSwitchStage]);

  /* On leave app
    useEffect(() => {
        const onBeforeLeave = async (e: BeforeUnloadEvent) => {
            if (sessionStarted)
                e.returnValue = "";
        };
        const onLeave = async () => {
            if (sessionStarted)
                await switchAvailability(true);
        };

        window.addEventListener("beforeunload", onBeforeLeave);
        window.addEventListener("unload", onLeave);
        return () => {
            window.removeEventListener("beforeunload", onBeforeLeave);
            window.removeEventListener("unload", onLeave);
        }
    }, [sessionStarted, switchAvailability]);
    */
};

export const AppStoreMiddleware = (props: {
  children: ReactNode;
}): JSX.Element => {
  useRouteChange();

  useSearchMiddleware();
  useAppointmentMiddleware();
  const dialog = useDialogMiddleware();

  return (
    <>
      {dialog.shownDialog && (
        <Dialog {...dialog.shownDialog} onClose={dialog.onClose} />
      )}
      {props.children}
    </>
  );
};
