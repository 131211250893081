import { useRouter } from "next/router";
import { useCallback } from "react";

import { useSliceStages, useSliceValues } from "../util";
import { appointmentSlice } from "./slice";
import { appointmentStageCheckers } from "./stages";
import { useInitAppointment, useRedirectToAppointment, useResetQuestionnaire } from "./middleware";
import { useData } from "../data/helpers";
import {
  answerPreRegQuestionnaire,
  answerPreScreening,
  createAppointment,
  fetchEstablished,
  fetchPreScreening,
  getConsents,
  getPreRegQuestionnaire,
  setInsurance,
  verifyInsurance
} from "../thunks";

import { transformToAppointmentDetails } from "@/components/UI/Appointments/AppointmentInfoList";

import {
  AppointmentInfoProps,
  AppointmentStage,
  AppointmentState,
  BE_PreScreeningQuestionAnswer
} from "@/types";
import { useAppDispatch } from "@/store/store";
import { appointmentToSearch } from "@/store/actions";
import { paramsStringToObject } from "@/helpers";
import { AsyncThunkAction } from "@reduxjs/toolkit";

export const useAppointment = () => {
  const v = useSliceValues(appointmentSlice);
  const s = useSliceStages<AppointmentStage, AppointmentState>(
    appointmentSlice
  );
  const dispatch = useAppDispatch();
  const dispatchWithReturn = useCallback(
    <T>(action: AsyncThunkAction<T, any, any>) =>
      dispatch(action).then((r) => r.payload as T),
    [dispatch]
  );

  const { push: routerPush } = useRouter();
  const { getTextByValue, getLocationById } = useData();

  const { valueOf, valueSet } = v;
  const { doctorName, time, timeEnd, visit, patient, idPatient, searchParams } =
    v.state;
  const { forceSwitchStage } = s;

  const getAppointmentType = useCallback(
    () =>
      transformToAppointmentDetails({
        patient,
        visitType: getTextByValue("visitTypes", visit.type),
        globalCategory: getTextByValue(
          "globalCategories",
          visit.globalCategory
        ),
        visitReason: getTextByValue("reasons", visit.reason)
      }),
    [getTextByValue, patient, visit]
  );

  const locationAddress =
    getLocationById(visit.location, visit.type)?.address1 ?? "";
  const getAppointmentInfo = useCallback(
    (withDetails = false): AppointmentInfoProps => ({
      dateString: valueOf<Date>("date")?.toString() ?? "",
      doctorName: doctorName,
      time: time,
      timeEnd: timeEnd,
      location: locationAddress ?? "",
      ...(withDetails ? { details: getAppointmentType() } : {})
    }),
    [valueOf, doctorName, time, timeEnd, locationAddress, getAppointmentType]
  );

  const quitPayment = useCallback(() => {
    valueSet("payment.clientSecret", "");
    forceSwitchStage(AppointmentStage.SIGN);
  }, [valueSet, forceSwitchStage]);

  const _fetchEstablished = useCallback(
    async () => dispatchWithReturn(fetchEstablished()),
    [dispatchWithReturn]
  );

  const _setInsurance = useCallback(
    async (insurance: AppointmentState["insurance"] | null) =>
      dispatch(setInsurance(insurance)),
    [dispatch]
  );

  const _verifyInsurance = useCallback(
    () => dispatchWithReturn(verifyInsurance()),
    [dispatchWithReturn]
  );

  const _fetchPreScreening = useCallback(
    () => dispatchWithReturn(fetchPreScreening()),
    [dispatchWithReturn]
  );
  const _answerPreScreening = useCallback(
    (answers: BE_PreScreeningQuestionAnswer[] = []) =>
      dispatchWithReturn(answerPreScreening(answers)),
    [dispatchWithReturn]
  );
  const _getPreRegQuestionnaire = useCallback(
    (id: number) => dispatch(getPreRegQuestionnaire(id)),
    [dispatch]
  );
  const _answerPreRegQuestionnaire = useCallback(
    (id: number) => dispatch(answerPreRegQuestionnaire(id)),
    [dispatch]
  );
  const _getConsents = useCallback(
    () => dispatchWithReturn(getConsents()),
    [dispatchWithReturn]
  );

  const book = useCallback(
    async () => dispatch(createAppointment()),
    [dispatch]
  );

  const setError = useCallback(
    (
      error: { message: string; code?: number | null; action?: string } | null
    ) =>
      dispatch(
        appointmentSlice.actions.setError(
          error
            ? {
                message: error.message,
                code: error.code ?? null,
                action: error.action ?? ""
              }
            : {
                message: "",
                code: null,
                action: ""
              }
        )
      ),
    [dispatch]
  );
  const removeError = useCallback(() => setError(null), [setError]);

  const backToSearch = useCallback(async () => {
    await routerPush({
      pathname: "/search",
      query: paramsStringToObject(searchParams)
    });
    dispatch(appointmentToSearch());
  }, [dispatch, routerPush, searchParams]);

  return {
    ...v,
    ...s,
    backToSearch,
    idPatient,
    book,
    quitPayment,
    getAppointmentInfo,
    getAppointmentType,
    canSwitchStage: (stage?: AppointmentStage) =>
      appointmentStageCheckers[stage ?? ((s.stage + 1) as AppointmentStage)](
        v.state
      ),
    setInsurance: _setInsurance,
    verifyInsurance: _verifyInsurance,
    fetchEstablished: _fetchEstablished,
    fetchPreScreening: _fetchPreScreening,
    answerPreScreening: _answerPreScreening,
    getPreRegQuestionnaire: _getPreRegQuestionnaire,
    answerPreRegQuestionnaire: _answerPreRegQuestionnaire,
    getConsents: _getConsents,
    isLoading: v.state.loading,
    errorMessage: v.state.errorMessage,
    errorCode: v.state.errorCode,
    errorAction: v.state.errorAction,
    removeError,
    setError
  };
};

export const useAppointmentMiddleware = () => {
  const app = useAppointment();

  useInitAppointment(app);
  useRedirectToAppointment(app);
  useResetQuestionnaire(app);
};

type UseAppointment = ReturnType<typeof useAppointment>;
export type AppointmentMiddleware = (appointment: UseAppointment) => void;
