export enum LoadState {
  DEFAULT,
  LOADING,
  LOADED
}

export enum Patient {
  ADULT = "ADULT",
  PEDIATRIC = "PEDIATRIC",
  PARENT = "EXPECTANT_PARENT"
}

export enum Practice {
  NEW = "NEW",
  ESTABLISHED = "ESTABLISHED"
}

export type Gender = "MALE" | "FEMALE" | string;

export enum VisitType {
  OFFICE = "OFFICE",
  ONLINE = "ONLINE",
  HOME = "HOME"
}

export type GlobalCategory = string;

export type DateISOString = string;

export enum PaymentType {
  SAME_CARD = "SAME_CARD",
  NEW_CARD = "NEW_CARD"
}
