import { dateToString, getToday } from "@/helpers";
import { Patient, SearchStage, SearchState } from "@/types";

const initialState: SearchState = {
  stage: SearchStage.SEARCH1,

  patient: Patient.PEDIATRIC,
  age: null,
  birthday: null,
  search1: {
    gender: null,
    type: null,
    globalCategory: null,
    reason: null,
    specialty: null
  },
  search2: {
    doctor: null,
    insurance: null,
    location: null,
    date: dateToString(getToday())
  },
  didInitParams: false,
  isSearching: false,
  isFetching1: false,
  isFetching2: false,
  results: [],
  selectedResult: null,
  selectedTime: null,
  sort: {},
  previousDate: dateToString(getToday())
};
export default initialState;
