import { ReactNode } from "react";
import { Provider } from "react-redux";

import { appStore } from "./store";
import { AppStoreMiddleware } from "./AppStoreMiddleware";

export const AppStoreProvider = (props: {
  children: ReactNode;
}): JSX.Element => {
  return (
    <Provider store={appStore}>
      <AppStoreMiddleware>{props.children}</AppStoreMiddleware>
    </Provider>
  );
};
