import { useCallback } from "react";
import { PayloadAction, Slice } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import _ from "@/lodash";

import { useAppDispatch, useAppSelector } from "./store";

import {
  dateToString,
  isIsoDate,
  stringToDate,
  updatePartialObject
} from "@/helpers";
import { InputOpts } from "@/types";
import { LOCAL_KEYS } from "@/constants";

const transformValueUp = (val: any) => {
  if (moment.isMoment(val) || moment.isDate(val))
    return dateToString(moment(val));
  return val;
};
const transformValueDown = (val: any) => {
  if (typeof val === "string" && isIsoDate(val)) return stringToDate(val);
  return val;
};

/* VALUES */
export const getValueReducer = <T extends object>() => ({
  set: (state: T, action: PayloadAction<{ key: string; value: any }>) =>
    _.set(state, action.payload.key, action.payload.value),
  setState: (state: T, action: PayloadAction<T>) => action.payload,
  setPartialState: (state: T, action: PayloadAction<Partial<T>>) =>
    updatePartialObject(state, action.payload, transformValueUp), // ({...state, ...action.payload})
  saveToStorage: (state: T, action: PayloadAction<{ key: string }>) => {
    sessionStorage.setItem(action.payload.key, JSON.stringify(state));
    return state;
  }
});

export const useSliceValues = <T1 extends object = any>(slice: Slice<T1>) => {
  const currentState = useAppSelector((s) => (s as any)[slice.name]) as T1;
  const dispatch = useAppDispatch();

  const valueSet = useCallback(
    <T extends any = any>(key: string, value: T) =>
      dispatch(slice.actions.set!({ key, value: transformValueUp(value) })),
    [dispatch, slice.actions.set]
  );
  const valueBind = useCallback(
    <T extends any = any>(key: string) =>
      (value: T) =>
        valueSet(key, value),
    [valueSet]
  );
  const valueOf = useCallback(
    <T extends any = any>(key: string, raw = false): T | null =>
      (raw
        ? _.get(currentState, key)
        : transformValueDown(_.get(currentState, key))) as T,
    [currentState]
  );
  const getInputOpts = useCallback(
    <T extends any = any>(key: string): InputOpts => ({
      value: valueOf<T>(key),
      onChange: valueBind<T>(key)
    }),
    [valueOf, valueBind]
  );
  const updateState = useCallback(
    (partial: Partial<typeof currentState>) =>
      dispatch(slice.actions.setPartialState(partial)),
    [dispatch, slice.actions]
  );
  const resetState = useCallback(
    (omitPaths?: string[]) =>
      !omitPaths
        ? dispatch(slice.actions.setState(slice.getInitialState()))
        : dispatch(
            slice.actions.setPartialState!(
              _.omit(slice.getInitialState(), omitPaths)
            )
          ),
    [dispatch, slice]
  );
  const resetValue = useCallback(
    (key?: string) => {
      if (!key) return resetState();
      const initState = slice.getInitialState();
      valueSet(key, _.get(initState, key));
    },
    [valueSet, slice, resetState]
  );
  const saveToStorage = useCallback(
    (key: string = LOCAL_KEYS.APPOINTMENT) =>
      dispatch(slice.actions.saveToStorage({ key })),
    [dispatch, slice.actions]
  );
  const setState = useCallback(
    (state: T1) => dispatch(slice.actions.setState(state)),
    [dispatch, slice.actions]
  );

  return {
    setState,
    saveToStorage,
    updateState,
    resetValue,
    resetState,
    valueSet,
    valueOf,
    valueBind,
    getInputOpts,
    state: currentState
  };
};

/* STAGES */
type StateWithStage<S extends number> = {
  [prop: string]: any;
  stage: S;
};

export const switchStage = <S extends number, T extends StateWithStage<S>>(
  state: T,
  stage: S,
  checkers: Record<S, (state: T) => boolean>
): T =>
  (!checkers[stage] ? false : checkers[stage](state))
    ? { ...state, stage }
    : state;

export const getStageReducer = <S extends number, T extends StateWithStage<S>>(
  checkers: Record<S, (state: T) => boolean>
) => ({
  switchStage: (state: T, action: PayloadAction<S | null>) =>
    switchStage(state, action.payload ?? ((state.stage + 1) as S), checkers),
  devForceSwitchStage: (state: T, action: PayloadAction<S>) => {
    state.stage = action.payload;
    return state;
  }
});

export const useSliceStages = <S extends number, T1 extends StateWithStage<S>>(
  slice: Slice<T1>
) => {
  const currentStage = useAppSelector((s) => (s as any)[slice.name].stage) as S;
  const dispatch = useAppDispatch();

  const switchStage = useCallback(
    (stage: S | null = null) => dispatch(slice.actions.switchStage(stage)),
    [dispatch, slice.actions]
  );

  const forceSwitchStage = useCallback(
    (s: S) => dispatch(slice.actions.devForceSwitchStage(s)),
    [dispatch, slice.actions]
  );

  return {
    stage: currentStage,
    switchStage,
    forceSwitchStage
    // FORCE SWITCH STAGE USED IN APPOINTMENTS !!!
  };
};
