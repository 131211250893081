import _ from "@/lodash";

export const isDev = () => process.env.NEXT_PUBLIC_DEV === "true";

export const nth = (n: number) => {
  if (n > 3 && n < 21) return "th";
  switch (n % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const isNotNull = (o: any | object = null): boolean => {
  if (o === null || o === "") return false;

  if (_.isObject(o))
    return _.reduce<object, boolean>(
      o,
      (res, val) => {
        return res && isNotNull(val);
      },
      true
    );

  return true;
};

export const arrayOf = (of: number, fill: any = 0) => Array(of).fill(fill);

export const notSet = (x: any) => x === undefined || x === null;

export const asyncSleep = (ms: number) =>
  new Promise((res) => setTimeout(res, ms));

export const randomOf = (of: number, from = 0) =>
  Math.floor((Math.random() * 100) % of) + from;

export const timeoutPromise = <T extends any = any>(
  fn: () => Promise<T> | T,
  timeout = 10000
): Promise<T> =>
  new Promise(async (res, rej) => {
    try {
      setTimeout(() => rej(new Error("Timed out!")), timeout);
      res(await fn());
    } catch (err: any) {
      rej(err);
    }
  });

export const toFixedLen = (str: string, len: number, fill = "") => {
  const missingLength = len - str.length;
  let ret = "";
  for (let i = 0; i < missingLength; i++) ret += fill;
  ret += str;
  return ret;
};
