import { useCallback } from "react";

import { dataSlice } from "./slice";
import { useAppDispatch } from "../store";
import { useSliceValues } from "../util";

import { LOCATIONS, MOCK_AGE_GROUPS } from "@/constants";
import { transformToOptions } from "@/helpers";
import { DataState, IDType, Option, VisitType } from "@/types";

export const useData = () => {
  const dispatch = useAppDispatch();

  const v = useSliceValues(dataSlice);
  const data = v.state;

  const setConstants = useCallback(
    (constants: DataState["constants"]) =>
      dispatch(dataSlice.actions.setConstants(constants)),
    [dispatch]
  );

  const setOptions1 = useCallback(
    (options: DataState["options1"]) =>
      dispatch(dataSlice.actions.setOptions1(options)),
    [dispatch]
  );

  const setOptions2 = useCallback(
    (options: DataState["options2"]) =>
      dispatch(dataSlice.actions.setOptions2(options)),
    [dispatch]
  );

  const setStatics = useCallback(
    (statics: DataState["static"]) =>
      dispatch(dataSlice.actions.setStatics(statics)),
    [dispatch]
  );

  const findById = useCallback(
    <T extends keyof DataState["constants"]>(collection: T, id: IDType) =>
      (data.constants[collection] as DataState["constants"][T][number][]).find(
        (x) => x.id === id
      ),
    [data.constants]
  );

  const getLocationById = useCallback(
    (id: IDType | null, type = VisitType.OFFICE) =>
      type === VisitType.OFFICE || type === null
        ? data.constants.locations.find((l) => l.id === id)
        : LOCATIONS[type][0],
    [data.constants]
  );

  const getOpts1 = useCallback(
    <
      T extends keyof DataState["options1"],
      K extends keyof DataState["options1"][T]
    >(
      key: T
    ) => data.options1[key],
    [data.options1]
  );
  const getOpts2 = useCallback(
    <
      T extends keyof DataState["options2"],
      K extends keyof DataState["options2"][T]
    >(
      key: T
    ) => data.options2[key],
    [data.options2]
  );

  const getOpts1TextByValue = useCallback(
    <
      T extends keyof DataState["options1"],
      K extends keyof DataState["options1"][T]
    >(
      key: T,
      val: any
    ) => (data.options1[key] as Option[]).find((x) => x.value === val)?.text,
    [data.options1]
  );

  return {
    ...v,
    set: v.valueSet,
    setConstants,
    setOptions1,
    setOptions2,
    setStatics,
    findById,
    getLocationById,
    getOpts1,
    getOpts2,
    getTextByValue: getOpts1TextByValue,

    bounds: data.static.bounds,
    sessionTimeout: data.static.session.timeout,
    // childAgeGroups: data.constants.childAgeGroups,
    childAgeGroups: MOCK_AGE_GROUPS,

    insurances: () =>
      transformToOptions(data.constants.insurances, "name", "id"),
    doctors: () => transformToOptions(data.constants.doctors, "name", "id"),
    locations: () => transformToOptions(data.constants.locations, "name", "id"),

    races: () => data.static.contact.races,
    ethnicities: () => data.static.contact.ethnicities,
    languages: () => data.static.contact.languages
  };
};
/*
export type DataMiddleware = (data: ReturnType<typeof useData>) => void;

export const useDateMiddleware = () => {
};
*/
