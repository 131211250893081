import { VisitType, Location } from "@/types";

// If true - redirects to STRIPE Checkout.
//
// If false - opens the Checkout Form in dialog.
//
export const IS_PAYMENT_EXTERNAL = true;

export const FORMS_DATA = {
  PRE_SCREENING: {
    HEADING: "Pre-appointment Questionnaire"
  },
  COSTS: {
    TEXT1: (
      <>
        Below please find the visit <b>cost estimate</b> of your responsibility
        for the common services you may incur based on your visit type.
      </>
    ),
    TEXT2: (
      <>
        Thank you. Below please find the visit <b>cost estimate</b> of your
        responsibility for the common services you may incur based on your visit
        type and clinical questionnaire.
      </>
    ),
    NOTE: `Note: The estimate of your costs represents only approximate amount of
                you may owe for the payment, deductible and/or outstanding balance based on
                the available information. While it may serve as a helpful guide, it is subject
                to change based on the actual services provided, the final cost and your
                responsibility determination.
        `,
    CONFIRMATION: "Please ensure that the information above is all correct."
  },
  INSURANCE: {
    SET: "Confirm insurance",
    VERIFY: "Verify information",
    VERIFY_FAILED: `
            Your insurance could not be verified as active. Please check your insurance name and ID again. 
            If you have inactive insurance or no insurance, please click on “Continue without insurance”
        `,
    CONTINUE_WITHOUT: "Continue without insurance"
  },
  QUESTIONS: {
    HEADING: "Clinical questionnaire:",
    CONFIRMATION: `Please review your answers before you click
                       CONFIRM as they will be permanently saved.
        `,
    CONSENT: `*I, the parent and/or the legal guardian of kest test, the person registered
                    for this visit hereby confirm that I have provided this health related information
                    which is true and to the best of my knowledge`
  },
  VISIT_INFO: {
    CONFIRMATION: "Before the next step please check the appointment details",
    HEADING: "Type of visit:",
    CHANGE: "Change visit"
  },
  CONFIRM: "Confirm",
  CHANGE: "Change"
};

export const LOCAL_KEYS = {
  APPOINTMENT: "appointment_",
  SEARCH: "search_"
};

export const LOCATIONS: {
  [key: string]: Location[];
} = {
  [VisitType.HOME]: [
    {
      id: 0,
      name: "At home",
      type: VisitType.HOME,
      address1: "Your Home",
      address2: "",
      address3: "",
      adults: false,
      children: true
    }
  ],
  [VisitType.ONLINE]: [
    {
      id: 1,
      name: "Telemedicine",
      type: VisitType.ONLINE,
      address1: "Virtual",
      address2: "",
      address3: "",
      adults: true,
      children: true
    }
  ]
};

export * from "./mock";
export * from "./validation";
export * from "./consents";
