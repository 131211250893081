import { createAction } from "@reduxjs/toolkit";

import { AppointmentState, Mapping, SearchResult, SearchState } from "@/types";
import { convertUsingMappig, fromString } from "@/helpers";

// Global Store Actions

export const appointmentToSearch = createAction("appointmentToSearch");

export const searchToAppointment = createAction(
  "searchToAppointment",
  (
    urlWithParams: string,
    searchToAppointmentMap: Mapping<
      SearchState,
      AppointmentState
    > = SEARCH_TO_APPOINTMENT_MAP
  ) => ({
    payload: {
      searchToAppointmentMap,
      urlWithParams
    }
  })
);

// export const appointmentToSearch = createAction("appointmentToSearch");

export const loadSearchFromParams = createAction(
  "loadSearchFromParams",
  (
    paramsString: string | null = null,
    paramsToSearchMap: Mapping<any, SearchState> = PARAMS_TO_SEARCH_MAP
  ) => ({
    payload: {
      paramsToSearchMap,
      paramsString
    }
  })
);

export const getSearchStateFromParams = (
  params: Record<string, string>,
  paramsToSearchMap: Mapping<any, SearchState> = PARAMS_TO_SEARCH_MAP
) => {
  const partialObj = convertUsingMappig<any, SearchState>(
    params,
    paramsToSearchMap,
    fromString
  );

  if (!!partialObj?.selectedTime) {
    if (
      !Object.keys(partialObj.selectedTime).includes("startTime") ||
      !Object.keys(partialObj.selectedTime).includes("endTime") ||
      !Object.keys(partialObj.selectedTime).includes("idTimeSlot")
    )
      delete partialObj["selectedTime"];
    else {
      const searchResult = convertUsingMappig<any, SearchResult>(
        params,
        PARAMS_TO_SEARCH_RESULT,
        fromString
      );
      searchResult.availableTimes = [partialObj.selectedTime];
      if (searchResult.doctor) searchResult.doctor.insurances = [];

      partialObj.selectedResult = searchResult as any;
    }
  }

  return partialObj;
};

const SEARCH_TO_APPOINTMENT_MAP: Mapping<SearchState, AppointmentState> = {
  patient: "patient",
  birthday: "info.birthday",
  age: "info.age",
  "search1.gender": "info.gender",
  "search1.type": "visit.type",
  "search1.globalCategory": "visit.globalCategory",
  "search1.reason": "visit.reason",
  "selectedResult.doctor.location": "visit.location",
  "selectedResult.doctor.id": "doctor",
  "selectedResult.doctor.name": "doctorName",
  "selectedResult.doctor.specialty": "specialty",
  "selectedResult.date": "date",
  "selectedTime.startTime": "time",
  "selectedTime.endTime": "timeEnd",
  "selectedTime.idTimeSlot": "idTimeSlot",
  "search2.insurance": "insurance.id"
};

const PARAMS_TO_SEARCH_MAP: Mapping<any, SearchState> = {
  age: "age",
  patient: "patient",
  birthday: "birthday",
  gender: "search1.gender",
  globalCategory: "search1.globalCategory",
  type: "search1.type",
  reason: "search1.reason",
  specialty: "search1.specialty",
  doctor: "search2.doctor",
  insurance: "search2.insurance",
  location: "search2.location",
  date: "search2.date",
  time: "selectedTime.startTime",
  timeEnd: "selectedTime.endTime",
  idTimeSlot: "selectedTime.idTimeSlot"
};

const PARAMS_TO_SEARCH_RESULT: Mapping<any, SearchResult> = {
  doctor: "doctor.id",
  doctorName: "doctor.name",
  specialty: "doctor.specialty",
  location: "doctor.location",
  date: "date"
};
