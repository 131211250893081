import { useCallback, useEffect, useState } from "react";
import _ from "@/lodash";

import { useAppDispatch, useAppSelector } from "../store";
import { dialogSlice } from "./slice";
import { Dialog, DialogResponse } from "@/types";

const {
  registerDialog: _registerDialog,
  unregisterDialog: _unregisterDialog,
  showFirstOpenDialog,
  setMessage,
  showDialog: _showDialog,
  hideDialog: _hideDialog
} = dialogSlice.actions;

let commonResolver: (value: DialogResponse) => Promise<void> = async () => {};

export const useDialog = (dialog: Dialog = { message: "" }) => {
  const { showId } = useAppSelector((s) => s.dialog);
  const dispatch = useAppDispatch();

  const [id] = useState(_.uniqueId());

  const hideDialog = useCallback(() => {
    dispatch(_hideDialog(id));
  }, [dispatch, id]);

  const showDialog = useCallback(
    (msg?: string, chainPromise?: () => Promise<any>) => {
      if (msg)
        dispatch(
          setMessage({
            index: id,
            message: msg
          })
        );
      dispatch(_showDialog(id));
      return new Promise<DialogResponse>((res) => {
        commonResolver = async (x) => {
          if (chainPromise) await chainPromise();
          hideDialog();
          res(x);
        };
      });
    },
    [dispatch, hideDialog, id]
  );

  const dialogSerialized = JSON.stringify(dialog);
  const registerDialog = useCallback(() => {
    dispatch(
      _registerDialog({
        index: id,
        dialog: JSON.parse(dialogSerialized)
      })
    );
  }, [dispatch, dialogSerialized, id]);

  const unregisterDialog = useCallback(() => {
    dispatch(_unregisterDialog(id));
  }, [dispatch, id]);

  useEffect(() => {
    registerDialog();
    return () => {
      unregisterDialog();
    };
  }, [registerDialog, unregisterDialog]);

  return {
    showDialog,
    hideDialog,
    id,
    isShown: id === showId
  };
};

export const useDialogMiddleware = () => {
  const state = useAppSelector((s) => s.dialog);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (state.showId === null) dispatch(showFirstOpenDialog());
  }, [dispatch, state.showId]);

  const shownDialog =
    state.showId !== null ? state.dialogs[state.showId] : null;

  return {
    shownDialog,
    onClose: (val: number) => commonResolver(val)
  };
};
