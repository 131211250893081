import { MouseEventHandler, ReactNode, useState } from "react";

import { moveWithMouse, useMounted } from "@/helpers";

import classes from "./Button.module.scss";

const Button = (props: {
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  loading?: boolean;
  autoLoading?: boolean;
  className?: string;
  noAnimate?: boolean;
  secondary?: boolean;
  bare?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  pressed?: boolean;
  children?: ReactNode;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const isLoading = props.loading || loading;

  const mounted = useMounted();

  return (
    <button
      type={props.type ?? "button"}
      disabled={props.disabled || isLoading}
      onClick={async (e) => {
        if (props.autoLoading) setLoading(true);
        if (props.onClick) await props.onClick(e);
        if (props.autoLoading && mounted) setLoading(false);
      }}
      {...(props.noAnimate ? {} : moveWithMouse("span"))}
      className={`
                       ${classes.btn} 
                       ${!props.noAnimate ? classes.animated : ""} 
                       ${props.secondary ? classes.secondary : ""}
                       ${props.bare ? classes.bare : ""}
                       ${props.pressed ? classes.pressed : ""}
                       ${props.className}
                   `}
    >
      {isLoading ? <i className="fas fa-spinner fa-spin" /> : props.children}
      {!props.noAnimate && <span className={classes.h} />}
    </button>
  );
};

export default Button;
